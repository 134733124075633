import { useFeatureEntitlements } from '@frontegg/react';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export enum SystemFeatureFlag {
  DEDUPLICATION_TOGGLE = 'ui_deduplicate_toggle',
  RISK_VIEW_CSV_EXPORT = 'ui_risk_view_csv_export',
  FINDINGS_IMPORT_FILE = 'ui_import_finding_file',
  FINDING_VIEW_INTELLIGENCE_TAB = 'ui_finding_view_intelligence_tab',
  TAGS_FILTER = 'ui_tags_filter',
  ASSETS_VIEW = 'ui_assets_view',
  AI_FILTERS = 'ui_ai_filters',
  DASHBOARD_SCORE_CARD_WIDGET = 'ui_dashboard_score_card_widget',
  DASHBOARD_SERVICE_ATTRIBUTES_FILTER = 'ui_dashboard_service_attributes_filter',
  FINDING_SSVC = 'ui_finding_ssvc_widget',
}

export enum NotEntitledJustification {
  MISSING_FEATURE = 'MISSING_FEATURE',
}

interface EntitledContentProps extends BaseComponentProps {
  flag: SystemFeatureFlag | string;
}

export const EntitledContent: FunctionComponent<EntitledContentProps> = ({
  flag,
  children = '',
}) => {
  const { isEntitled, justification } = useFeatureEntitlements(flag);

  return isEntitled ? <>{children}</> : <></>;
};
