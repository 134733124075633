import { AuthorizedContent } from '@frontegg/react';
import { Box, Button, Switch, Typography } from '@mui/material';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { ApplicationLogo } from 'Application/interfaces/item';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { ItemWithIcon } from 'Common/components/ItemWithIcon/ItemWithIcon';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { getActionColumn } from 'Integrations/fixtures/connections-list-table-columns';
import moment from 'moment';
import { iconMap } from 'Organization/components/OrganizationNodeLabel/OrganizationNodeLabel';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import React, { useState } from 'react';
import { RuleStatus } from 'Settings/interfaces/RiskCustomizationConfig';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import AvatarBox from 'shared/components/AvatarBox';
import BusinessImpactChip from 'shared/components/BusinessImpactChip';
import CommonIconCell from 'shared/components/CommonIconCell';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowTextDisplay,
  OverflowDirection,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { reportRuleFrequencyOptions } from 'shared/fixtures/data/report-rule.data';
import { NotificationRuleDataHandler } from 'shared/handlers/notification-rule-data.handler';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  ReportRule,
  ReportRuleModel,
  reportRuleRecipientOptions,
} from 'shared/models/data/report-rule.model';
import RiskBadge from '../RiskBadge/RiskBadge';
import StakeholdersGroup from '../StakeholdersGroup/StakeholdersGroup';
import WorkspacesCountIcon from '../WorkspacesCount/WorkspacesCount';
import { dateTimeFormat } from 'shared/services/date.service';
import { capitalize } from 'lodash';
import { ReportRuleActionCellRenderer } from '../ReportRuleActionCellRenderer';
import ReportRuleRecipientsCellRenderer from '../ReportRuleRecipientsCellRenderer.tsx';

const notificationRuleDataHandler = new NotificationRuleDataHandler();

interface SettingsColumnsProps {
  t: any;
  useDeleteMutation: UseMutation<any>;
  onDelete: (decision: boolean, deleteResponse?: any) => void;
  requiredPermissions: ApplicationPermission[] | undefined;
  logos?: ApplicationLogo[];
  setShouldIgnoreRowClick?: any;
  onUpdate?: (updateBody: any) => void;
  isUpdating?: boolean;
}

interface ReportColumnProps extends SettingsColumnsProps {
  onSendReport?: (reportRule: ReportRule) => void;
  onEditReport?: (reportRule: ReportRuleModel) => void;
}
interface SettingsActionColumn extends SettingsColumnsProps {
  title: string;
  messageKey: string;
}

interface ReportsActionColumn extends SettingsActionColumn {
  onSendReport?: (reportRule: ReportRule) => void;
  onEditReport?: (reportRule: ReportRuleModel) => void;
}

function getSettingsActionColumn({
  t,
  useDeleteMutation,
  title,
  messageKey,
  onDelete,
  requiredPermissions,
  setShouldIgnoreRowClick,
}: SettingsActionColumn) {
  return [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      resizable: false,
      comparator: () => 0,
      initialFlex: 2,
      align: 'right',
      headerClass: 'column-disable-sort',
      cellRenderer: (params: any) => {
        return (
          <AuthorizedContent
            requiredPermissions={requiredPermissions}
            render={(isAuthorized) => (
              <DeleteWithConfirm
                id={params?.data?.id}
                setShouldIgnoreRowClick={setShouldIgnoreRowClick}
                useMutation={useDeleteMutation}
                title={title}
                message={t(
                  'settings.details.areYouSureYouWantToDeleteApplication',
                  {
                    param1:
                      messageKey === 'severity'
                        ? FindingSeverity[params?.data?.severity]
                        : params?.data[messageKey],
                  }
                )}
                callback={onDelete}
                disabled={!isAuthorized}
              >
                <Button
                  className="delete-action"
                  disabled={!isAuthorized}
                  variant="text"
                >
                  <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />
                </Button>
              </DeleteWithConfirm>
            )}
          ></AuthorizedContent>
        );
      },
    },
  ];
}

function getReportRuleActionColumn({
  t,
  useDeleteMutation,
  title,
  messageKey,
  onDelete,
  requiredPermissions,
  setShouldIgnoreRowClick,
  onSendReport,
  onEditReport,
}: ReportsActionColumn) {
  return [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      resizable: false,
      headerClass: 'column-disable-sort',
      width: 170,
      cellRenderer: (params: any) => {
        return (
          <ReportRuleActionCellRenderer
            params={params}
            requiredPermissions={requiredPermissions}
            onEditReport={onEditReport}
            onSendReport={onSendReport}
            setShouldIgnoreRowClick={setShouldIgnoreRowClick}
            useDeleteMutation={useDeleteMutation}
            title={title}
            messageKey={messageKey}
            onDelete={onDelete}
            t={t}
          />
        );
      },
    },
  ];
}

class SettingsGridColumns {
  static getServiceColumns({ t }: any) {
    return [
      {
        field: 'name',
        initialFlex: 4,
        comparator: () => 0,
        headerName: t('settings.table.columns.businessUnits.serviceName'),
        cellRenderer: (params: any) => {
          return (
            <TextOverflow
              direction={OverflowDirection.WIDTH}
              type={OverflowTextDisplay.ELLIPSIS}
            >
              {params?.data?.name}
            </TextOverflow>
          );
        },
      },
      {
        field: 'businessUnitCriticality',
        initialFlex: 4,
        comparator: () => 0,
        headerName: t('settings.table.columns.businessUnits.serviceImpact'),
        cellRenderer: (params: any) => {
          return (
            <BusinessImpactChip impact={params?.data?.businessUnitImpact} />
          );
        },
      },
      {
        field: 'workspaces',
        headerName: t('settings.table.columns.businessUnits.workspaces'),
        initialFlex: 5,
        sortable: false,
        headerClass: 'column-disable-sort',
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            <div className="workspaces-icon-container">
              {!!params?.data?.associatedReposCount && (
                <WorkspacesCountIcon
                  count={params.data.associatedReposCount}
                  iconType={SVG_ICON_TYPES.CODE_ICON}
                  id={'associated-repos-count'}
                />
              )}
              {!!params?.data?.environmentsCount && (
                <WorkspacesCountIcon
                  count={params.data.environmentsCount}
                  iconType={SVG_ICON_TYPES.CLOUD_ICON}
                  id={'cloud-environment-count'}
                />
              )}
              {!!params?.data?.resourceTagsCount && (
                <WorkspacesCountIcon
                  count={params.data.resourceTagsCount}
                  iconType={SVG_ICON_TYPES.TAGS_ICON}
                />
              )}
            </div>
          );
        },
      },
      {
        field: 'ticketingSystem',
        headerName: t('settings.table.columns.businessUnits.ticketing'),
        initialFlex: 5,
        sortable: false,
        headerClass: 'column-disable-sort',
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            params?.data.ticketingSystem && (
              <CommonIconCell
                iconUrl={params?.data.ticketingSystem?.logoUrl}
                label={
                  params?.data?.ticketingSystem?.projectName ||
                  t(`common.missingValue`)
                }
                linkUrl={params?.data?.ticketingSystem?.link}
                maxWidth={120}
              />
            )
          );
        },
      },
      {
        field: 'factors',
        sortable: false,
        headerClass: 'column-disable-sort',
        headerName: t('settings.table.columns.businessUnits.riskBadges'),
        initialFlex: 6,
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            <div className="risk-badge-container">
              <RiskBadge
                params={params}
                configType={RiskCustomizationConfigTypes.buExternalFacing}
                iconType={SVG_ICON_TYPES.ARROW_UP_RIGHT_FROM_SQUARE_ICON}
              />

              <RiskBadge
                params={params}
                configType={RiskCustomizationConfigTypes.buSensitiveData}
                iconType={SVG_ICON_TYPES.EYES_ICON}
              />

              <RiskBadge
                params={params}
                configType={RiskCustomizationConfigTypes.buCompliance}
                iconType={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON}
              />
            </div>
          );
        },
      },
      {
        field: 'stakeholders',
        sortable: false,
        headerClass: 'column-disable-sort',
        headerName: t('settings.table.columns.businessUnits.stakeholders'),
        initialFlex: 6,
        comparator: () => 0,
        cellRenderer: (params: any) => {
          const { stakeholders } = params.data;

          return <StakeholdersGroup stakeholders={stakeholders} />;
        },
      },
    ] as Array<ColDef | ColGroupDef>;
  }
  static getCloudWorkspacesColumns({
    t,
    useDeleteMutation,
    onDelete,
    requiredPermissions,
    setShouldIgnoreRowClick,
  }: SettingsColumnsProps) {
    const actionColumn = getSettingsActionColumn({
      t,
      useDeleteMutation,
      title: t('settings.table.columns.cloudWorkspaces.deleteWorkspace'),
      messageKey: 'alias',
      onDelete,
      requiredPermissions,
      setShouldIgnoreRowClick,
    });
    return [
      {
        field: 'cloudProvider',
        initialFlex: 4,
        comparator: () => 0,
        headerName: t('settings.table.columns.cloudWorkspaces.provider'),
        cellRenderer: (params: any) => {
          return (
            <Box
              ml={2}
              display="inline-flex"
              alignItems={'center'}
              columnGap={2}
              flexDirection="row"
            >
              <OpusImageIcon
                alt={params?.data?.cloudProvider}
                src={params?.data?.cloudProviderLogo}
                title={params?.data?.cloudProviderLogo}
                imageClassName="cloudProviderLogo"
              />
              <Typography className="body-1" variant="inherit">
                {params?.data?.cloudProvider}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'alias',
        headerName: t('settings.table.columns.cloudWorkspaces.workspaceName'),
        initialFlex: 6,
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.alias}
            </Typography>
          );
        },
      },
      {
        field: 'externalId',
        headerName: t('settings.table.columns.cloudWorkspaces.workspaceId'),
        initialFlex: 6,
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.externalId}
            </Typography>
          );
        },
      },
      {
        field: 'type',
        headerName: t('settings.table.columns.cloudWorkspaces.workspaceType'),
        sortable: true,
        initialFlex: 6,
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.type}
            </Typography>
          );
        },
      },
      {
        field: 'environmentType',
        headerName: t(
          'settings.table.columns.cloudWorkspaces.workspaceEnvironmentType'
        ),
        initialFlex: 4,
        comparator: () => 0,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.environmentType}
            </Typography>
          );
        },
      },
      {
        field: 'businessUnitId',
        comparator: () => 0,
        headerName: t('settings.table.columns.cloudWorkspaces.services'),
        initialFlex: 7,
        sortable: false,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          return (
            params?.data?.businessUnit?.name && (
              <div
                className="clickable-item"
                onClick={() => {
                  setShouldIgnoreRowClick(true);
                  window.open(
                    `${window.location.origin}/settings?activeTabId=business-units&mode=view&businessUnitId=${params?.data?.businessUnit?.id}`,
                    '_blank'
                  );
                }}
              >
                <Typography className="body-1" variant="inherit">
                  {params?.data?.businessUnit?.name}
                </Typography>
              </div>
            )
          );
        },
      },

      ...actionColumn,
    ] as Array<ColDef | ColGroupDef>;
  }
  static getTeamsColumns({
    t,
    logos,
    useDeleteMutation,
    onDelete,
    requiredPermissions,
    setShouldIgnoreRowClick,
  }: SettingsColumnsProps) {
    const actionColumn = getSettingsActionColumn({
      t,
      useDeleteMutation,
      title: t('settings.table.columns.teams.deleteTeam'),
      messageKey: 'name',
      onDelete,
      requiredPermissions,
      setShouldIgnoreRowClick,
    });
    return [
      {
        field: 'name',
        headerName: t(`settings.table.columns.teams.teamName`),
        initialFlex: 8,
        sortable: true,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.name}
            </Typography>
          );
        },
      },
      {
        field: 'owner',
        headerName: t('settings.table.columns.teams.teamOwner'),
        sortable: true,
        initialFlex: 8,
        align: 'left',
        cellRenderer: (params: any) => {
          return (
            <Button
              className="owner-email body-1"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                event.stopPropagation();
                (event as any).ignore = true;
                const email = params?.data?.owner;
                const mailToUrl = `mailto:${email}`;
                window.open(mailToUrl, '_blank');
              }}
            >
              <Typography className="body-1" variant="inherit" align="left">
                {params?.data?.owner}
              </Typography>
            </Button>
          );
        },
      },
      {
        field: 'communicationAppId',
        headerName: t('settings.table.columns.teams.communicationChannel'),
        sortable: true,
        initialFlex: 8,
        cellRenderer: (params: any) => {
          const logo = logos?.find(
            (logo: ApplicationLogo) =>
              params?.data?.communicationAppId === logo.id
          )?.logo;

          return logo ? (
            <ItemWithIcon
              maxWidth={220}
              icon={
                <OpusImageIcon
                  alt={params?.data?.communicationAppId}
                  src={logo}
                  title={params?.data?.communicationAppId}
                  imageClassName="cloudProviderLogo"
                />
              }
              title={params?.data?.communicationChannelId}
              missingTitleKey={''}
              displayIconOutline={false}
            />
          ) : (
            <Typography className="body-1" variant="inherit" align="left">
              {params?.data?.communicationChannelId || '-'}
            </Typography>
          );
        },
      },
      ...actionColumn,
    ] as Array<ColDef | ColGroupDef>;
  }
  static getSlaColumns({
    t,
    useDeleteMutation,
    onDelete,
    requiredPermissions,
    setShouldIgnoreRowClick,
  }: SettingsColumnsProps) {
    const actionColumn = getSettingsActionColumn({
      t,
      useDeleteMutation,
      title: t('settings.table.columns.sla.deleteSla'),
      messageKey: 'severity',
      onDelete,
      requiredPermissions,
      setShouldIgnoreRowClick,
    });
    return [
      {
        field: 'severity',
        headerName: t(`settings.table.columns.sla.severity`),
        sortable: false,
        initialFlex: 8,

        cellRenderer: (params: any) => {
          return (
            params?.data?.severity && (
              <CommonSeverityChip severityNumber={params?.data?.severity} />
            )
          );
        },
      },
      {
        field: 'value',
        headerName: t('settings.table.columns.sla.slaHours'),
        sortable: false,
        initialFlex: 8,

        cellRenderer: (params: any) => {
          return (
            params?.data?.value && (
              <Typography className="body-1" variant="inherit">
                {`${params?.data?.value / 3600}H`}
              </Typography>
            )
          );
        },
      },
      ...actionColumn,
    ] as Array<ColDef | ColGroupDef>;
  }

  static getUsersColumns({
    t,
    useDeleteMutation,
    onDelete,
    requiredPermissions,
    setShouldIgnoreRowClick,
    onUpdate,
  }: SettingsColumnsProps) {
    const actionColumn = getActionColumn({
      t,
      useDeleteMutation,
      title: t('settings.table.columns.users.deleteUser'),
      onDelete,
      onUpdate,
      message: 'Are you sure you want to delete this user?',
      requiredPermissions,
      setShouldIgnoreRowClick,
      columns: [],
    });
    return [
      {
        field: 'name',
        headerName: t(`settings.table.columns.users.user`),
        sortable: true,
        initialFlex: 2,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          return (
            <AvatarBox
              name={params?.data?.name}
              profileUrl={params?.data?.profilePictureUrl}
              role={params?.data?.email}
            ></AvatarBox>
          );
        },
      },
      {
        field: 'roles',
        headerName: t('settings.table.columns.users.roles'),
        sortable: false,
        initialFlex: 1.5,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          const joinedValue = params?.data?.roles
            .map((item: any) => item.name)
            .join(', ');
          return (
            <TextOverflow
              type={OverflowTextDisplay.ELLIPSIS}
              maxWidth={160}
              direction={OverflowDirection.WIDTH}
            >
              {joinedValue}
            </TextOverflow>
          );
        },
      },

      {
        field: 'orgStructureInfo',
        headerName: t('settings.table.columns.users.organization'),
        sortable: false,
        width: '320',
        maxWidth: '320',
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          const orgStructureInfo = params?.data?.orgStructureInfo;

          const formatInfo = (info: any[], pluralLabel: string) => {
            const length = info?.length;

            if (!length) return '';

            const label =
              length === 1 ? info[0].name : `${length} ${pluralLabel}`;
            return `${label}`;
          };

          const scopeInfo = formatInfo(orgStructureInfo?.scopesInfo, 'Scopes');
          const groupInfo = formatInfo(orgStructureInfo?.groupsInfo, 'Groups');
          const dataInfo = formatInfo(orgStructureInfo?.dataInfo, 'Services');

          const elements = [
            {
              label: scopeInfo,
              icon: iconMap['scope' as OrganizationNodeType],
            },
            {
              label: groupInfo,
              icon: iconMap['logical' as OrganizationNodeType],
            },
            { label: dataInfo, icon: iconMap['data' as OrganizationNodeType] },
          ];
          const nonEmptyElements = elements.filter((element) => element.label);

          let maxWidth = 300;

          if (nonEmptyElements.length === 2) {
            maxWidth = 200;
          } else if (nonEmptyElements.length === 3) {
            maxWidth = 80;
          }

          return (
            <>
              {elements.map((element, index) => {
                if (!element.label) return null;

                return (
                  <Box className="users-org-column-content" key={index}>
                    {index > 0 && elements[index - 1]?.label ? ', ' : ''}
                    {element.icon}
                    <TextOverflow
                      type={OverflowTextDisplay.ELLIPSIS}
                      maxWidth={maxWidth}
                      direction={OverflowDirection.WIDTH}
                    >
                      {element.label}
                    </TextOverflow>
                  </Box>
                );
              })}
            </>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: t('settings.table.columns.users.joined'),
        sortable: true,
        initialFlex: 2,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          return params.data?.createdAt ? (
            moment(params.data?.createdAt).format(dateTimeFormat)
          ) : (
            <TextOverflow
              type={OverflowTextDisplay.ELLIPSIS}
              maxWidth={160}
              direction={OverflowDirection.WIDTH}
              className="bordered-item-4 "
              px={'10px'}
              py={'5px'}
            >
              Pending Approval
            </TextOverflow>
          );
        },
      },
      {
        field: 'lastSeen',
        headerName: t('settings.table.columns.users.lastSeen'),
        sortable: true,
        initialFlex: 2,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          return (
            params.data?.lastSeen &&
            moment(params.data?.lastSeen).format(dateTimeFormat)
          );
        },
      },
      ...actionColumn,
    ] as Array<ColDef | ColGroupDef>;
  }
  static getNotificationRulesColumns({
    t,
    logos,
    useDeleteMutation,
    onDelete,
    requiredPermissions,
    setShouldIgnoreRowClick,
    onUpdate,
    isUpdating,
  }: SettingsColumnsProps) {
    const actionColumn = getSettingsActionColumn({
      t,
      useDeleteMutation,
      title: t('settings.table.columns.notificationRules.deleteRule'),
      messageKey: 'name',
      onDelete,
      requiredPermissions,
      setShouldIgnoreRowClick,
    });
    return [
      {
        field: 'name',
        headerName: t(`settings.table.columns.notificationRules.name`),
        initialFlex: 3,
        sortable: true,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.name}
            </Typography>
          );
        },
      },
      {
        field: 'description',
        headerName: t('settings.table.columns.notificationRules.description'),
        sortable: true,
        initialFlex: 4,
        align: 'left',
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.description}
            </Typography>
          );
        },
      },
      {
        field: 'triggers',
        headerName: t('settings.table.columns.notificationRules.triggers'),
        sortable: false,
        initialFlex: 4,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          const triggerSentence =
            notificationRuleDataHandler.transformTriggersToPresentationalSentence(
              params.data?.triggers
            );
          return (
            <Typography
              title={triggerSentence}
              className="body-1"
              variant="inherit"
            >
              {triggerSentence}
            </Typography>
          );
        },
      },
      {
        field: 'properties',
        headerName: t('settings.table.columns.notificationRules.properties'),
        sortable: false,
        initialFlex: 5,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          const propertiesSentence =
            notificationRuleDataHandler.transformPropertiesToPresentationalSentence(
              params.data?.properties
            );
          return (
            <Typography
              title={propertiesSentence}
              className="body-1"
              variant="inherit"
            >
              {propertiesSentence}
            </Typography>
          );
        },
      },
      {
        field: 'status',
        headerName: t('settings.table.columns.notificationRules.on_off'),
        sortable: false,
        initialFlex: 2,
        headerClass: 'column-disable-sort',
        cellRenderer: (params: any) => {
          return (
            <AuthorizedContent
              requiredPermissions={requiredPermissions}
              render={(isAuthorized) => {
                const [status, setStatus] = useState<RuleStatus>(
                  params.data?.status as RuleStatus
                );
                return (
                  <Switch
                    classes={{
                      root: 'opus-switch-field-1 ignore-row-click',
                    }}
                    defaultChecked={status === RuleStatus.ENABLED}
                    onClick={() => {
                      setStatus((previousStatus) => {
                        return previousStatus === RuleStatus.ENABLED
                          ? RuleStatus.DISABLED
                          : RuleStatus.ENABLED;
                      });
                      onUpdate &&
                        onUpdate({
                          id: params.data?.id,
                          status:
                            status === RuleStatus.ENABLED
                              ? RuleStatus.DISABLED
                              : RuleStatus.ENABLED,
                        });
                    }}
                    disabled={!isAuthorized}
                  />
                );
              }}
            />
          );
        },
      },
      ...actionColumn,
    ] as Array<ColDef | ColGroupDef>;
  }

  static getReportRulesColumns({
    t,
    useDeleteMutation,
    onDelete,
    requiredPermissions,
    setShouldIgnoreRowClick,
    onSendReport,
    onEditReport,
  }: ReportColumnProps) {
    const actionColumn = getReportRuleActionColumn({
      t,
      useDeleteMutation,
      title: t('settings.table.columns.reportRules.deleteRule'),
      messageKey: 'name',
      onDelete,
      requiredPermissions,
      setShouldIgnoreRowClick,
      onSendReport,
      onEditReport,
    });
    return [
      {
        field: 'name',
        headerName: t(`settings.table.columns.reportRules.name`),
        initialFlex: 3,
        sortable: true,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params?.data?.name}
            </Typography>
          );
        },
      },
      {
        field: 'type',
        headerName: t('settings.table.columns.reportRules.type'),
        sortable: true,
        comparator: (a, b) => {
          const labelA = a?.[0]?.label?.toLowerCase() || ''; // Ensure lowercase comparison and handle undefined values
          const labelB = b?.[0]?.label?.toLowerCase() || '';

          if (labelA < labelB) return -1;
          if (labelA > labelB) return 1;
          return 0;
        },
        initialFlex: 3,
        align: 'left',
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {capitalize(params.data.type)}
            </Typography>
          );
        },
      },
      {
        field: 'reportMode',
        headerName: t('settings.table.columns.reportRules.mode'),
        sortable: true,
        initialFlex: 3,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params.data.reportMode === 'multi' ? 'Role-Based' : 'Static'}
            </Typography>
          );
        },
      },
      {
        field: 'recipients',
        headerName: t('settings.table.columns.reportRules.recipients'),
        sortable: true,
        initialFlex: 3,
        cellRenderer: (params: any) => {
          return <ReportRuleRecipientsCellRenderer params={params} />;
        },
      },
      {
        field: 'frequencyType',
        headerName: t('settings.table.columns.reportRules.frequency'),
        sortable: true,
        initialFlex: 5,
        comparator: (a, b) => {
          const indexA = reportRuleFrequencyOptions.findIndex(
            (option) => option.value === a
          );
          const indexB = reportRuleFrequencyOptions.findIndex(
            (option) => option.value === b
          );

          return indexA - indexB;
        },
        cellRenderer: (params: any) => {
          const selectedOption = reportRuleFrequencyOptions.find(
            (frequencyOption) =>
              frequencyOption.value ===
              params?.data?.scheduleSettings?.frequencyType
          );

          return (
            <Typography className="body-1" variant="inherit">
              {selectedOption?.label}
            </Typography>
          );
        },
      },
      {
        field: 'lastTimeSent',
        headerName: t('settings.table.columns.reportRules.lastTimeSent'),
        sortable: true,
        initialFlex: 5,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params.data?.lastTimeSent
                ? moment(params.data?.lastTimeSent).format(dateTimeFormat)
                : ''}
            </Typography>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: t('settings.table.columns.reportRules.createdAt'),
        sortable: true,
        initialFlex: 5,
        cellRenderer: (params: any) => {
          return (
            <Typography className="body-1" variant="inherit">
              {params.data?.createdAt
                ? moment(params.data?.createdAt).format(dateTimeFormat)
                : ''}
            </Typography>
          );
        },
      },
      ...actionColumn,
    ] as Array<ColDef | ColGroupDef>;
  }
}

export default SettingsGridColumns;
