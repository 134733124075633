import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import { allOption } from 'Common/components/Autocomplete/Autocomplete';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import DatePicker from 'Common/components/DatePicker';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useCreateReportRuleMutation,
  useFetchReportRuleQuery,
  useGenerateReportRulePreviewUrlMutation,
  useUpdateReportRuleMutation,
} from 'Settings/store/api';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import {
  operationalDashboardFilterOptions,
  operationalDashboardTrendMetricsTypeOptions,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { ReportRuleDataHandler } from 'shared/handlers/report-rule-data.handler';
import {
  NotificationSettingsOptionList,
  useFetchReportRulesOptionItems,
} from 'shared/hooks/useFetchReportRuleOptionItems';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  reportRuleCompareByOptions,
  reportRuleConfigurationTypeOptions,
  reportRuleFrequencyOptions,
  ReportRuleMode,
  ReportRuleModel,
  reportRuleModeOptions,
  reportRuleRecipientOptions,
  ReportRuleRoleRecipient,
  reportRuleSecurityWidgetOptions,
  securityReportRuleSchema,
} from 'shared/models/data/report-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import * as yup from 'yup';

const reportRuleDataHandler = new ReportRuleDataHandler();

interface SecurityReportRuleDrawerContentProps extends BaseComponentProps {
  onCancel?: () => void;
  options: {
    scopeOptions?: Array<AutocompleteOption>;
    groupOptions?: Array<AutocompleteOption>;
    serviceOptions?: Array<AutocompleteOption>;
    emailOptions?: Array<AutocompleteOption>;
  };
}

export const SecurityReportRuleDrawerContent: FunctionComponent<
  SecurityReportRuleDrawerContentProps
> = ({ onCancel, options }) => {
  const navigate = useNavigate();

  const [areOptionsReady, setAreOptionsReady] = useState<boolean>(false);

  const { groupOptions, scopeOptions, emailOptions } = options;

  const { t: translation } = useTranslation();

  const [searchParams] = useQueryParams();

  const [loading, setLoading] = useState<boolean>(true);

  const isEditMode = Boolean(searchParams?.ruleId);

  const { data: reportRule, isFetching: reportRuleLoading } =
    useFetchReportRuleQuery(searchParams?.ruleId as string, {
      skip: !searchParams?.ruleId,
      refetchOnMountOrArgChange: true,
    });

  const {
    emailOptionList,
    findingTypeOptionList,
    findingTypeOptionListLoading,
    getGroupsOptionList,
    getServiceOptionList,
    groupsOptionsLoading,
    scopesOptionsLoading,
    groupsOptionList,
    scopesOptionList,
    servicesOptionList,
    servicesOptionsLoading,
  } = useFetchReportRulesOptionItems(reportRule);

  const [
    createReportRule,
    { isLoading: createReportRuleLoading, isSuccess: createReportRuleSuccess },
  ] = useCreateReportRuleMutation();

  const [
    updateReportRule,
    { isLoading: updateReportRuleLoading, isSuccess: updateReportRuleSuccess },
  ] = useUpdateReportRuleMutation();

  const [
    generateReportRulePreviewUrl,
    {
      isLoading: generatingReportRulePreviewUrl,
      data: reportRulePreviewUrlPayload,
    },
  ] = useGenerateReportRulePreviewUrlMutation();

  useEffect(() => {
    if (reportRulePreviewUrlPayload) {
      window.open(reportRulePreviewUrlPayload, '_blank');
    }
  }, [reportRulePreviewUrlPayload]);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    reset: resetForm,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(yup.object().shape(securityReportRuleSchema)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      scopes: {
        value: 'All',
        label: 'All',
      },
      groups: {
        value: 'All',
        label: 'All',
      },
    } as any,
  });

  const formValues = getValues();

  const reportMode = watch('mode');
  const scopes = watch('scopes');
  const groups = watch('groups');

  const recipients = watch('recipients');

  const selectedMetrics = watch('metrics');

  const onDrawerClose = () => {
    resetForm();
    onCancel && onCancel();
  };

  useEffect(() => {
    if (emailOptions && scopeOptions && groupOptions && servicesOptionList) {
      setAreOptionsReady(true);
    }
  }, [emailOptions, scopeOptions, groupOptions, servicesOptionList]);

  useEffect(() => {
    if (isEditMode && reportRule && areOptionsReady) {
      const defaultValues =
        reportRuleDataHandler.transformReportRuleToSecurityReportRuleFormData(
          reportRule,
          emailOptions,
          {
            scopeOptions: scopeOptions,
            groupOptions: groupOptions,
            serviceOptions: servicesOptionList,
          }
        );

      resetForm(defaultValues);
      setLoading(false);
    } else if (!isEditMode) {
      setLoading(false);
    }
  }, [isEditMode, reportRule, resetForm, areOptionsReady]);

  useEffect(() => {
    if (createReportRuleSuccess || updateReportRuleSuccess) {
      onDrawerClose();
    }
  }, [createReportRuleSuccess, updateReportRuleSuccess]);

  useEffect(() => {
    getGroupsOptionList(scopes?.value === 'All' ? undefined : scopes?.value);
  }, [scopes?.value]);

  useEffect(() => {
    let startNodeId = undefined;

    if (!Boolean(groups)) {
      startNodeId = scopes?.value === 'All' ? undefined : scopes?.value;
    } else {
      startNodeId =
        groups?.value === 'All'
          ? scopes?.value === 'All'
            ? undefined
            : scopes?.value
          : groups?.value;
    }

    getServiceOptionList(startNodeId);
  }, [scopes?.value, groups?.value]);

  const setMultiSelectFormValue = (
    model: string,
    selection: AutocompleteOption | Array<AutocompleteOption> | Array<string>
  ) => {
    setFormValue(model as any, selection);
  };

  const recipientsOptions = useMemo<Array<AutocompleteOption>>(() => {
    return reportMode?.value === ReportRuleMode.STATIC_REPORT
      ? emailOptionList
      : reportRuleRecipientOptions;
  }, [reportMode?.value]);

  const isRecipientsSingleSelect = useMemo<boolean>(() => {
    return Boolean(reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT);
  }, [reportMode?.value]);

  const onSaveReportRule = () => {
    handleSubmit(
      (values: any) => {
        const postData =
          reportRuleDataHandler.transformSecurityReportRuleFormDataToPostData({
            ...values,
            services:
              values.services.length ===
              servicesOptionList?.filter(
                (serviceOption) => serviceOption.value !== allOption.value
              )?.length
                ? [allOption]
                : values.services,
          });

        if (isEditMode) {
          updateReportRule({
            id: reportRule.id,
            ...postData,
          });
        } else {
          createReportRule(postData);
        }
      },
      (errors) => {
        console.log('Errors are', errors);
      }
    )();
  };

  const onPreviewReportRule = () => {
    handleSubmit(
      (values: any) => {
        const postData =
          reportRuleDataHandler.transformSecurityReportRuleFormDataToPostData({
            ...values,
            services:
              values.services.length ===
              servicesOptionList?.filter(
                (serviceOption) => serviceOption.value !== allOption.value
              )?.length
                ? [allOption]
                : values.services,
          });

        generateReportRulePreviewUrl(postData);
      },
      (errors) => {
        console.log('Errors are', errors);
      }
    )();
  };

  const renderRecipientsAutocomplete = () => {
    if (isRecipientsSingleSelect) {
      return (
        <Autocomplete
          optionList={recipientsOptions}
          model="recipients"
          onChangeCallBack={(
            model: string,
            selection: Array<AutocompleteOption> | AutocompleteOption
          ) => {
            const selections = Array.isArray(selection)
              ? selection
              : [selection];

            setMultiSelectFormValue(model, selections);
          }}
          classes={{
            root: 'multi-select-field-1 ',
            paper: 'multi-select-field-paper-1',
            inputRoot: errors.recipients ? 'input-validation-error-1' : '',
          }}
          enableCheckbox
          areOptionsLoaded
          single
          disabled={!Boolean(reportMode)}
          values={
            formValues?.recipients?.length
              ? formValues?.recipients[0]
              : undefined
          }
          initialSelectedValues={
            formValues?.recipients?.length
              ? formValues?.recipients[0]
              : undefined
          }
        />
      );
    }

    return (
      <Autocomplete
        optionList={recipientsOptions}
        model="recipients"
        onChangeCallBack={(
          model: string,
          selection: Array<AutocompleteOption> | AutocompleteOption
        ) => {
          const selections = Array.isArray(selection) ? selection : [selection];

          setMultiSelectFormValue(model, selections);
        }}
        classes={{
          root: 'multi-select-field-1 ',
          paper: 'multi-select-field-paper-1',
          inputRoot: errors.recipients ? 'input-validation-error-1' : '',
        }}
        enableCheckbox
        areOptionsLoaded
        freeSolo
        disabled={!Boolean(reportMode)}
        values={formValues.recipients}
        initialSelectedValues={formValues.recipients}
      />
    );
  };

  const getInfoMessageForReportRuleConfiguration = () => {
    if (recipients) {
      switch (recipients[0]?.value) {
        case ReportRuleRoleRecipient.SERVICE_OWNERS:
          return translation(`reportRule.security.serviceOwnersMessage`);
        case ReportRuleRoleRecipient.GROUP_OWNERS:
          return translation(`reportRule.security.groupOwnersMessage`);
        case ReportRuleRoleRecipient.SCOPE_OWNERS:
          return translation(`reportRule.security.scopeOwnersMessage`);
        default:
          return '';
      }
    }
    return '';
  };

  const renderInfoMessage = () => {
    const infoMessage = getInfoMessageForReportRuleConfiguration();

    if (infoMessage.length)
      return (
        <div className="report-rule-info-message">
          <div className="report-rule-info-message-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
          </div>
          <div className="report-rule-info-message-text">{infoMessage}</div>
        </div>
      );

    return <></>;
  };

  return reportRuleLoading || loading ? (
    <div className="notification-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="report-rule-drawer-content">
      <div className="report-rule-drawer-content-header">
        <div className="report-rule-drawer-content-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_ICON} />
        </div>
        <div className="report-rule-drawer-content-header-title-area">
          <div className="report-rule-drawer-content-header-title">
            {translation('reportRule.security.headerTitle')}
          </div>
          <div className="report-rule-drawer-content-header-sub-title">
            {translation('reportRule.security.headerSubtitle')}
          </div>
        </div>
      </div>
      <div className="report-rule-drawer-content-body">
        <div className="report-rule-drawer-content-body-section">
          <InputLabelWrapper
            label={translation('reportRule.security.nameLabel')}
          >
            <input
              type="text"
              className={`text-field-1 ${
                errors.name ? 'input-validation-error-1' : ''
              }`}
              {...register('name')}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.security.descriptionLabel')}
            isOptional
          >
            <input
              type="text"
              className={`text-field-1 ${
                errors.description ? 'input-validation-error-1' : ''
              }`}
              {...register('description')}
            />
          </InputLabelWrapper>
        </div>

        <ContentSection
          title={translation('reportRule.security.reportConfiguration')}
        >
          <InputLabelWrapper
            label={translation('reportRule.security.widgetsLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.security.widgetsTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleSecurityWidgetOptions}
              model="widgets"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.widgets ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.widgets}
              initialSelectedValues={formValues.widgets}
            />
          </InputLabelWrapper>
          <div className="report-rule-drawer-content-body-metrics-section">
            <div className="report-rule-drawer-content-body-metrics-section-title">
              {translation('reportRule.security.metricsTitle')}
            </div>
            <div className="report-rule-drawer-content-body-metrics-section-inputs">
              <InputLabelWrapper
                label={translation('reportRule.security.metricsLabel')}
                components={{
                  EndIcon: (
                    <CommonTooltip
                      title={
                        <div className="report-rule-drawer-content-image-tooltip">
                          <span className="report-rule-drawer-content-image-tooltip-text">
                            {translation('reportRule.security.metricsTooltip')}
                          </span>
                          <img src="https://res.cloudinary.com/opus-security/image/upload/v1733302945/common-images-svg/metrics.svg" />
                        </div>
                      }
                      placement="top"
                    >
                      <span>
                        <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                      </span>
                    </CommonTooltip>
                  ),
                }}
              >
                <Autocomplete
                  optionList={operationalDashboardTrendMetricsTypeOptions}
                  model="metrics"
                  onChangeCallBack={(
                    model: string,
                    selection: Array<AutocompleteOption> | AutocompleteOption
                  ) => {
                    setFormValue('trendingChartMetrics', []);
                    setMultiSelectFormValue(model, selection);
                  }}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.metrics ? 'input-validation-error-1' : '',
                  }}
                  enableCheckbox
                  areOptionsLoaded
                  values={formValues.metrics}
                  initialSelectedValues={formValues.metrics}
                />
              </InputLabelWrapper>
              <InputLabelWrapper
                label={translation('reportRule.security.trendingMetricsLabel')}
                components={{
                  EndIcon: (
                    <CommonTooltip
                      title={
                        <div className="report-rule-drawer-content-image-tooltip">
                          {translation(
                            'reportRule.security.trendingMetricsTooltip'
                          )}
                          <img src="https://res.cloudinary.com/opus-security/image/upload/v1733302946/common-images-svg/trend-metrics-chart.svg" />
                        </div>
                      }
                      placement="top"
                    >
                      <span>
                        <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                      </span>
                    </CommonTooltip>
                  ),
                }}
              >
                <Autocomplete
                  optionList={selectedMetrics || []}
                  model="trendingChartMetrics"
                  onChangeCallBack={setMultiSelectFormValue}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.trendingChartMetrics
                      ? 'input-validation-error-1'
                      : '',
                  }}
                  enableCheckbox
                  areOptionsLoaded
                  disabled={!Boolean(selectedMetrics?.length)}
                  values={formValues.trendingChartMetrics}
                  initialSelectedValues={formValues.trendingChartMetrics}
                />
              </InputLabelWrapper>
              <InputLabelWrapper
                label={translation('reportRule.security.timeFrameLabel')}
                components={{
                  EndIcon: (
                    <CommonTooltip
                      title={
                        <span className="campaign-live-tooltip-text">
                          {translation('reportRule.security.timeFrameTooltip')}
                        </span>
                      }
                      placement="top"
                    >
                      <span>
                        <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                      </span>
                    </CommonTooltip>
                  ),
                }}
              >
                <Autocomplete
                  optionList={operationalDashboardFilterOptions}
                  model="timeFrame"
                  onChangeCallBack={setMultiSelectFormValue}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.timeFrame
                      ? 'input-validation-error-1'
                      : '',
                  }}
                  enableCheckbox
                  areOptionsLoaded
                  single
                  disabled={!Boolean(selectedMetrics?.length)}
                  values={formValues.timeFrame}
                  initialSelectedValues={formValues.timeFrame}
                />
              </InputLabelWrapper>
            </div>
          </div>
          <InputLabelWrapper
            label={translation('reportRule.security.domainLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.security.domainTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
            isOptional
          >
            <Autocomplete
              optionList={findingTypeOptionList}
              loading={findingTypeOptionListLoading}
              model="domains"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.domains ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.domains}
              initialSelectedValues={formValues.domains}
            />
          </InputLabelWrapper>
        </ContentSection>

        <ContentSection title={translation('reportRule.security.reportScope')}>
          <InputLabelWrapper
            label={translation('reportRule.security.scopeLabel')}
          >
            <Autocomplete
              optionList={scopesOptionList}
              loading={scopesOptionsLoading}
              model="scopes"
              onChangeCallBack={(
                model: string,
                selection: Array<AutocompleteOption> | AutocompleteOption
              ) => {
                setFormValue('groups', undefined);
                setFormValue('services', []);
                setMultiSelectFormValue(model, selection);
              }}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.scopes ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              single
              areOptionsLoaded
              values={formValues.scopes}
              initialSelectedValues={formValues.scopes}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.security.groupLabel')}
          >
            <Autocomplete
              optionList={groupsOptionList}
              loading={groupsOptionsLoading}
              model="groups"
              onChangeCallBack={(
                model: string,
                selection: Array<AutocompleteOption> | AutocompleteOption
              ) => {
                setFormValue('services', []);
                setMultiSelectFormValue(model, selection);
              }}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.groups ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              single
              areOptionsLoaded
              values={formValues.groups}
              initialSelectedValues={formValues.groups}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.security.servicesLabel')}
            isOptional
          >
            <Autocomplete
              optionList={servicesOptionList?.filter(
                (serviceOption) => serviceOption.value !== 'All'
              )}
              loading={servicesOptionsLoading}
              model="services"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.services ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.services}
              initialSelectedValues={formValues.services}
              enableAllSelection
              enableVirtualization
            />
          </InputLabelWrapper>
        </ContentSection>

        <ContentSection title={translation('reportRule.security.recipients')}>
          <InputLabelWrapper
            label={translation('reportRule.security.reportModeLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.security.reportModeTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleModeOptions}
              single
              model="mode"
              onChangeCallBack={(
                model: string,
                selection: Array<AutocompleteOption> | AutocompleteOption
              ) => {
                setFormValue('recipients', []);
                setMultiSelectFormValue(model, selection);
              }}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.mode ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.mode}
              initialSelectedValues={formValues.mode}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.security.recipientsLabel')}
          >
            {renderRecipientsAutocomplete()}
          </InputLabelWrapper>

          {renderInfoMessage()}
        </ContentSection>

        <ContentSection
          title={translation('reportRule.security.schedulingFrequency')}
        >
          <InputLabelWrapper label="">
            <DatePicker
              label={translation('reportRule.security.startingOnLabel')}
              model="startingOn"
              minDate={new Date()}
              onChangeCallBack={(model: string, values: Array<string>) => {
                setMultiSelectFormValue(model, {
                  value: values[0],
                });
              }}
              range={false}
              classes={{
                root: 'report-rule-date-picker',
              }}
              placeholder=""
              initialSelectedValues={[formValues.startingOn?.value]}
              TitleAdnornment={
                <>
                  <CommonTooltip
                    title={
                      <span className="campaign-live-tooltip-text">
                        {translation('reportRule.security.startingOnTooltip')}
                      </span>
                    }
                    placement="top"
                  >
                    <span>
                      <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                    </span>
                  </CommonTooltip>

                  <div className="input-label-optional">{`(Optional)`}</div>
                </>
              }
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.security.frequencyLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.security.frequencyTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleFrequencyOptions}
              single
              model="frequency"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.frequency ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.frequency}
              initialSelectedValues={formValues.frequency}
            />
          </InputLabelWrapper>
        </ContentSection>
      </div>

      <div className="report-rule-drawer-content-footer">
        <div className="report-rule-drawer-content-preview-button">
          <Button
            className="button-secondary"
            onClick={() => {
              onPreviewReportRule();
            }}
            disabled={generatingReportRulePreviewUrl}
          >
            {generatingReportRulePreviewUrl ? (
              <CircularProgress size={14} />
            ) : (
              <></>
            )}
            <span>{translation('reportRule.comparative.previewButton')}</span>
          </Button>
        </div>
        <div className="report-rule-drawer-content-submit-buttons">
          <Button
            className="button-secondary"
            onClick={() => {
              onDrawerClose();
            }}
          >
            {translation('reportRule.security.cancelButton')}
          </Button>
          <Button
            className="button-primary"
            onClick={() => {
              onSaveReportRule();
            }}
          >
            {translation(
              `reportRule.security.${
                createReportRuleLoading || updateReportRuleLoading
                  ? 'savingButton'
                  : 'saveButton'
              }`
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
