import { createApi } from '@reduxjs/toolkit/query/react';
import { Team, TeamCreate, TeamUpdate } from 'Settings/interfaces/Teams';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { Sla, SlaCreate, SlaUpdate } from 'Settings/interfaces/Sla';
import { BusinessUnitEnvironment } from '../interfaces/BusinessUnitEnvironment';
import { CloudWorkspace } from '../interfaces/CloudWorkspace';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import {
  ExtendedBusinessUnitCreateModel,
  ExtendedBusinessUnitModel,
} from 'shared/models/data/extended-business-unit.model';
import {
  NotificationRule,
  NotificationRulePropertyType,
} from 'shared/models/data/notification-rule.model';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { ReportRule } from 'shared/models/data/report-rule.model';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import { SettingsUpdateTypeEnum } from 'Settings/interfaces/SettingsUpdateType.enum';
import { SlaConfigRuleModel } from 'shared/models/data/sla-config-rule.model';
import { RiskConfig } from 'Settings/interfaces/RiskCustomizationConfig';
import { SlaRuleDataHandler } from 'shared/handlers/sla-rule-data.handler';

export interface CloudWorkspaceCreate {
  cloudProvider: string;
  externalId: string;
  alias?: string;
  additionalInfo?: any;
  environmentType?: string;
}

export interface AzureAccountConnect {
  azureTenantId: string;
  subscriptionId: string;
  cloudWorkspaceAlias: string;
}

export interface CloudAccountConnect {
  cloudWorkspaceName: string;
  externalID: string;
  roleARN: string;
  allowsRemediation?: boolean;
  accountType?: string;
}
export interface CloudWorkspaceUpdate {
  item: CloudWorkspaceCreate;
  id: string;
}

export interface FullApplicationCreate {
  environments: BusinessUnitEnvironment[];
  name: string;
  associatedRepos: string[];
  unitOwner: string;
  teamId?: string;
  businessUnitCriticality?: number;
  communicationAppId?: string;
}
export type RiskSettingsFactors = {
  [key in RiskCustomizationConfigTypes]: {
    weight: number;
    multiplier: number;
  };
};

export interface RiskSettingsFactories {
  factors: RiskSettingsFactors;
  severityRanges: any;
}

export interface FullApplicationUpdate {
  item: FullApplicationCreate;
  id: string;
}

enum SettingsApiTags {
  SETTINGS = 'Settings',
  SERVICE = 'service',
  NOTIFICATION_RULES = 'NotificationRules',
  REPORT_RULES = 'ReportRules',
  SLA_RULES = 'SlaRules',
  RISK_CONFIG = 'RiskConfig',
}
const ruleDataHandler = new SlaRuleDataHandler();

const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  tagTypes: [
    SettingsApiTags.SERVICE,
    SettingsApiTags.SETTINGS,
    SettingsApiTags.NOTIFICATION_RULES,
    SettingsApiTags.REPORT_RULES,
    SettingsApiTags.SLA_RULES,
    SettingsApiTags.RISK_CONFIG,
  ],
  endpoints: (builder) => ({
    searchServices: builder.mutation({
      query: (body) => ({
        url: `business-units/search`,
        method: 'POST',
        body,
      }),
    }),
    fetchBusinessUnit: builder.query({
      query: (businessUnitId: string) => ({
        url: `business-units/${businessUnitId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response as ExtendedBusinessUnitModel;
      },
    }),

    createBusinessUnit: builder.mutation<ExtendedBusinessUnitCreateModel, any>({
      query: (itemCreate) => ({
        url: 'business-units',
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: [SettingsApiTags.SERVICE],
    }),
    updateBusinessUnit: builder.mutation<ExtendedBusinessUnitCreateModel, any>({
      query: (updateDto) => ({
        url: `business-units/${updateDto.id}`,
        method: 'PATCH',
        body: updateDto,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return [SettingsApiTags.SERVICE];
      },
    }),
    deleteBusinessUnit: builder.mutation({
      query: (id) => ({
        url: `business-units/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SettingsApiTags.SERVICE],
    }),
    connectCloudAccount: builder.mutation<CloudWorkspace, CloudAccountConnect>({
      query: (itemCreate) => ({
        url: 'connections/cloud/aws/single',
        method: 'POST',
        body: itemCreate,
      }),
      transformResponse: async (response) => {
        return response as CloudWorkspace;
      },
    }),
    connectAzureAccount: builder.mutation<CloudWorkspace, AzureAccountConnect>({
      query: (itemCreate) => ({
        url: 'connections/cloud/azure/single',
        method: 'POST',
        body: itemCreate,
      }),
      transformResponse: async (response) => {
        return response as CloudWorkspace;
      },
    }),
    fetchCloudWorkspaces: builder.mutation<CloudWorkspace[], void>({
      query: () => 'cloud-workspaces',
      transformResponse: async (response) => {
        return response as CloudWorkspace[];
      },
    }),
    searchCloudWorkspaces: builder.mutation({
      query: (payload) => ({
        url: 'cloud-workspaces/search',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SettingsApiTags.SETTINGS],
    }),
    createCloudWorkspace: builder.mutation<
      CloudWorkspace,
      CloudWorkspaceCreate
    >({
      query: (itemCreate) => ({
        url: 'cloud-workspaces',
        method: 'POST',
        body: itemCreate,
      }),
      transformResponse: async (response) => {
        return response as CloudWorkspace;
      },
      invalidatesTags: [SettingsApiTags.SETTINGS],
    }),
    updateCloudWorkspace: builder.mutation<
      CloudWorkspace,
      CloudWorkspaceUpdate
    >({
      query: (updateDto) => ({
        url: `cloud-workspaces/${updateDto.id}`,
        method: 'PATCH',
        body: updateDto.item,
      }),
      transformResponse: async (response) => {
        return response as CloudWorkspace;
      },
      invalidatesTags: [SettingsApiTags.SETTINGS],
    }),
    deleteCloudWorkspace: builder.mutation<void, string>({
      query: (id) => ({
        url: `cloud-workspaces/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SettingsApiTags.SETTINGS],
    }),
    fetchTeamsList: builder.query<Team[], void>({
      query: () => 'teams',
      transformResponse: async (response) => {
        return response as Team[];
      },
    }),
    fetchTeams: builder.mutation<Team[], void>({
      query: () => 'teams',
      transformResponse: async (response) => {
        return response as Team[];
      },
    }),
    createTeam: builder.mutation<Team, TeamCreate>({
      query: (itemCreate) => ({
        url: 'teams',
        method: 'POST',
        body: itemCreate,
      }),
      transformResponse: async (response) => {
        return response as Team;
      },
    }),
    updateTeam: builder.mutation<Team, TeamUpdate>({
      query: (updateDto) => ({
        url: `teams/${updateDto.id}`,
        method: 'PATCH',
        body: updateDto.item,
      }),
      transformResponse: async (response) => {
        return response as Team;
      },
    }),
    deleteTeam: builder.mutation<void, string>({
      query: (id) => ({
        url: `teams/${id}`,
        method: 'DELETE',
      }),
    }),
    fetchSla: builder.mutation<Sla[], void>({
      query: () => 'slas',
      transformResponse: async (response: Sla[]) => {
        const responseTransormed = response.map((res) => ({
          ...res,
          severityLabel: FindingSeverity[res.severity],
        }));
        return responseTransormed as Sla[];
      },
    }),
    deleteSla: builder.mutation<void, string>({
      query: (id) => ({
        url: `slas/${id}`,
        method: 'DELETE',
      }),
    }),
    createSla: builder.mutation<Sla, SlaCreate>({
      query: (itemCreate) => ({
        url: 'slas',
        method: 'POST',
        body: itemCreate,
      }),
      transformResponse: async (response) => {
        return response as Sla;
      },
    }),
    updateSla: builder.mutation<Sla, SlaUpdate>({
      query: (updateDto) => ({
        url: `slas/${updateDto.id}`,
        method: 'PATCH',
        body: updateDto.item,
      }),
      transformResponse: async (response) => {
        return response as Sla;
      },
    }),
    fetchNotificationRules: builder.query<NotificationRule[], void>({
      query: () => 'automations/notifications/rules',
      transformResponse: async (response) => {
        return response as NotificationRule[];
      },
      providesTags: [SettingsApiTags.NOTIFICATION_RULES],
    }),
    fetchNotificationRule: builder.query<NotificationRule, string>({
      query: (notificationRuleId: string) => ({
        url: `automations/notifications/rules/${notificationRuleId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response as NotificationRule;
      },
    }),
    createNotificationRule: builder.mutation<NotificationRule, any>({
      query: (itemCreate) => ({
        url: 'automations/notifications/rules',
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: [SettingsApiTags.NOTIFICATION_RULES],
    }),
    updateNotificationRule: builder.mutation<
      ExtendedBusinessUnitCreateModel,
      any
    >({
      query: (updateDto) => ({
        url: `automations/notifications/rules/${updateDto.id}`,
        method: 'PATCH',
        body: updateDto,
      }),
      invalidatesTags: [SettingsApiTags.NOTIFICATION_RULES],
    }),
    deleteNotificationRule: builder.mutation<void, string>({
      query: (id) => ({
        url: `automations/notifications/rules/${id}`,
        method: 'DELETE',
      }),
      transformResponse: async (response) => {},
      invalidatesTags: [SettingsApiTags.NOTIFICATION_RULES],
    }),

    getNotificationRulesFilters: builder.mutation<
      Array<AutocompleteOption>,
      { field: NotificationRulePropertyType }
    >({
      query: ({ field }) => ({
        url: `automations/notifications/rules/filters/${field}`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as Array<AutocompleteOption>;
      },
    }),

    fetchReportRules: builder.query<ReportRule[], void>({
      query: () => 'automations/reports/rules',
      transformResponse: async (response) => {
        return response as ReportRule[];
      },
      providesTags: [SettingsApiTags.REPORT_RULES],
    }),
    fetchReportRule: builder.query<any, string>({
      query: (reportRuleId: string) => ({
        url: `automations/reports/rules/${reportRuleId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response as ReportRule;
      },
    }),
    createReportRule: builder.mutation<ReportRule, any>({
      query: (itemCreate) => ({
        url: 'automations/reports/rules',
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: [SettingsApiTags.REPORT_RULES],
    }),
    updateReportRule: builder.mutation<Partial<ReportRule>, any>({
      query: (updateDto) => ({
        url: `automations/reports/rules/${updateDto.id}`,
        method: 'PATCH',
        body: updateDto,
      }),
      invalidatesTags: [SettingsApiTags.REPORT_RULES],
    }),
    deleteReportRule: builder.mutation<void, string>({
      query: (id) => ({
        url: `automations/reports/rules/${id}`,
        method: 'DELETE',
      }),
      transformResponse: async (response) => {},
      invalidatesTags: [SettingsApiTags.REPORT_RULES],
    }),
    generateReportRulePreviewUrl: builder.mutation<string, any>({
      query: (itemCreate) => ({
        url: 'automations/reports/rules/generatePreviewUrl',
        method: 'POST',
        body: itemCreate,
      }),
    }),
    fetchWorkspaceFilterInformationForField: builder.mutation({
      query: (payload) => ({
        url: `cloud-workspaces/filters/${payload.field}`,
        method: 'POST',
      }),
    }),
    sendReportRule: builder.mutation<void, string>({
      query: (id) => ({
        url: `automations/reports/rules/${id}/process`,
        method: 'POST',
      }),
    }),
    fetchRiskFactories: builder.query<RiskSettingsFactories, any>({
      query: () => 'automations/risk/customization',
      transformResponse: async (response) => {
        return response as RiskSettingsFactories;
      },
      providesTags: [SettingsApiTags.SETTINGS],
    }),
    updateRiskFactories: builder.mutation<any, any>({
      query: (body) => ({
        url: `automations/risk/customization/update/${SettingsUpdateTypeEnum.RISK_FACTORS}`,
        method: 'POST',
        body: body,
      }),
    }),
    getSlaConfigRules: builder.query<Array<SlaConfigRuleModel>, any>({
      query: () => ({
        url: `slas/config/rules`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return ruleDataHandler.transformRuleResponse(
          response as Array<SlaConfigRuleModel>
        ) as Array<SlaConfigRuleModel>;
      },
      providesTags: [SettingsApiTags.SLA_RULES],
    }),
    getSlaConfigRuleById: builder.mutation<SlaConfigRuleModel, string>({
      query: (id: string) => ({
        url: `slas/config/rules/${id}`,
        method: 'GET',
      }),
    }),
    createSlaConfigRule: builder.mutation({
      query: (body: any) => ({
        url: `slas/config/rules`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SettingsApiTags.SLA_RULES],
    }),
    updateSlaConfigRuleById: builder.mutation({
      query: (payload: any) => ({
        url: `slas/config/rules/${payload.id}`,
        method: 'PATCH',
        body: payload.body,
      }),
      invalidatesTags: [SettingsApiTags.SLA_RULES],
    }),
    deleteSlaConfigRuleById: builder.mutation({
      query: (id: string) => ({
        url: `slas/config/rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SettingsApiTags.SLA_RULES],
    }),
    createRiskCustomuzation: builder.mutation<any, any>({
      query: (body) => ({
        url: 'automations/risk-config/rules',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [SettingsApiTags.RISK_CONFIG],
    }),
    updateRiskCustomuzation: builder.mutation<any, any>({
      query: (body) => ({
        url: `automations/risk-config/rules/${body.id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [SettingsApiTags.RISK_CONFIG],
    }),
    fetchRiskCustomizationItems: builder.query<RiskConfig[], string>({
      query: () => ({
        url: 'automations/risk-config/rules',
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return ruleDataHandler.transformRuleResponse(
          response as Array<RiskConfig>
        ) as Array<RiskConfig>;
      },
      providesTags: [SettingsApiTags.RISK_CONFIG],
    }),
    deleteRiskCustomizationItem: builder.mutation({
      query: (id) => ({
        url: `automations/risk-config/rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SettingsApiTags.RISK_CONFIG],
    }),
    fetchRiskCustomizationItem: builder.query<any, string>({
      query: (id: string) => ({
        url: `automations/risk-config/rules/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response as any;
      },
    }),
  }),
});

export default settingsApi;

export const {
  useFetchBusinessUnitQuery,
  useCreateBusinessUnitMutation,
  useUpdateBusinessUnitMutation,
  useDeleteBusinessUnitMutation,
  useConnectCloudAccountMutation,
  useConnectAzureAccountMutation,
  useFetchCloudWorkspacesMutation,
  useCreateCloudWorkspaceMutation,
  useUpdateCloudWorkspaceMutation,
  useDeleteCloudWorkspaceMutation,
  useFetchSlaMutation,
  useDeleteSlaMutation,
  useCreateSlaMutation,
  useUpdateSlaMutation,
  useCreateTeamMutation,
  useFetchTeamsMutation,
  useFetchTeamsListQuery,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useFetchNotificationRulesQuery,
  useFetchNotificationRuleQuery,
  useCreateNotificationRuleMutation,
  useUpdateNotificationRuleMutation,
  useDeleteNotificationRuleMutation,
  useGetNotificationRulesFiltersMutation,
  useFetchReportRuleQuery,
  useFetchReportRulesQuery,
  useCreateReportRuleMutation,
  useUpdateReportRuleMutation,
  useDeleteReportRuleMutation,
  useSearchCloudWorkspacesMutation,
  useFetchWorkspaceFilterInformationForFieldMutation,
  useSendReportRuleMutation,
  useFetchRiskFactoriesQuery,
  useUpdateRiskFactoriesMutation,
  useSearchServicesMutation,
  useGetSlaConfigRulesQuery,
  useGetSlaConfigRuleByIdMutation,
  useCreateSlaConfigRuleMutation,
  useUpdateSlaConfigRuleByIdMutation,
  useDeleteSlaConfigRuleByIdMutation,
  useCreateRiskCustomuzationMutation,
  useUpdateRiskCustomuzationMutation,
  useFetchRiskCustomizationItemsQuery,
  useDeleteRiskCustomizationItemMutation,
  useFetchRiskCustomizationItemQuery,
  useGenerateReportRulePreviewUrlMutation,
} = settingsApi;
