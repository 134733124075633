import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  useDeleteNotificationRuleMutation,
  useDeleteReportRuleMutation,
  useFetchReportRulesQuery,
  useSendReportRuleMutation,
  useUpdateNotificationRuleMutation,
} from 'Settings/store/api';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import { DrawerMode } from 'shared/enums/business-unit.enum';
import { NotificationRule } from 'shared/models/data/notification-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  ReportRule,
  ReportRuleCreationType,
  ReportRuleType,
} from 'shared/models/data/report-rule.model';
import ReportRuleDrawerPanel from '../ReportRuleDrawerPanel';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import { Alert, Button, MenuItem, MenuList, Popover } from '@mui/material';
import ComparativeReportRuleDrawerContent from '../ComparativeReportRuleDrawerContent';
import AddIcon from '@mui/icons-material/Add';
import { useSearchParams } from 'react-router-dom';
import SecurityReportRuleDrawerContent from '../SecurityReportRuleDrawerContent';
import { useFetchReportRulesOptionItems } from 'shared/hooks/useFetchReportRuleOptionItems';
import { useGetOrganizationOptions } from 'shared/hooks/useGetOrganizationData';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface ReportRuleListProps extends BaseComponentProps {}

export const ReportRuleList: FunctionComponent<ReportRuleListProps> = () => {
  const { t: translation } = useTranslation();
  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const [tableItems, setTableItems] = useState<ReportRule[] | null>(null);
  const userPermissions = usePermissions();
  const hasReportRuleReadPermission = true;

  const [searchParams, setSearchParams] = useSearchParams();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const reportRuleOptions = useFetchReportRulesOptionItems();

  const organizationOptions = useGetOrganizationOptions();

  const handleMenuPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuPopoverClose = () => {
    setAnchorEl(null);
  };

  const [formCancelationModalOpen, setFormCancelationModalOpen] =
    useState<boolean>(false);

  const { data: reportRules, isLoading: reportRulesLoading } =
    useFetchReportRulesQuery();

  const [updateNotificationRule, { isLoading: notificationRuleUpdating }] =
    useUpdateNotificationRuleMutation();

  const [
    sendReportRule,
    {
      isLoading: sendReportRuleLoading,
      error: sendReportError,
      isSuccess: sendReportSuccess,
      reset: resetSendReportPayload,
    },
  ] = useSendReportRuleMutation();

  useEffect(() => {
    if (searchParams.get('mode')) {
      setPopoverOpen(true);
    }
  }, [searchParams.get('mode')]);

  useEffect(() => {
    if (reportRules) {
      setTableItems(reportRules);
    }
  }, [reportRules]);

  useEffect(() => {
    setTimeout(() => {
      resetSendReportPayload();
    }, 3000);
  }, [sendReportError, sendReportSuccess]);

  const sendReportRuleHandler = (reportRule: ReportRule) => {
    sendReportRule(reportRule.id as string);
  };

  const drawerOpenHandler = (type: ReportRuleCreationType) => {
    handleMenuPopoverClose();

    setSearchParams({
      activeTabId: SettingsTabPanel.REPORT_RULES,
      mode: DrawerMode.CREATE,
      type,
    });
  };

  const rowClickHandler = (rule: any) => {
    if (
      userPermissions.includes(
        ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE
      )
    ) {
      setSearchParams({
        ...searchParams,
        activeTabId: SettingsTabPanel.REPORT_RULES,
        mode: DrawerMode.EDIT,
        ruleId: rule.id as string,
        type: rule?.type,
      });
    }
  };

  const notificationRulesColumns = useMemo(() => {
    return SettingsGridColumns.getReportRulesColumns({
      t: translation,
      useDeleteMutation: useDeleteReportRuleMutation,
      onDelete,
      onUpdate: updateNotificationRule,
      setShouldIgnoreRowClick,
      requiredPermissions: [
        ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE,
      ],
      isUpdating: notificationRuleUpdating,
      onSendReport: sendReportRuleHandler,
      onEditReport: rowClickHandler,
    });
  }, []);

  const drawerCloseHandler = () => {
    setPopoverOpen(false);
    setSearchParams({
      activeTabId: SettingsTabPanel.REPORT_RULES,
    });
  };

  const menuPopoverOpen = Boolean(anchorEl);

  const id = menuPopoverOpen ? 'report-rule-menu-popover' : undefined;

  function onDelete(decision: boolean) {}

  const renderDrawerContent = () => {
    const reportType = searchParams.get('type');

    if (reportType === ReportRuleCreationType.COMPARATIVE) {
      return (
        <ComparativeReportRuleDrawerContent
          onCancel={drawerCloseHandler}
          options={{
            groupOptions: organizationOptions.groupsOptions,
            scopeOptions: organizationOptions.scopesOptions,
            emailOptions: organizationOptions.emailOptionList,
          }}
        />
      );
    }

    return (
      <SecurityReportRuleDrawerContent
        onCancel={drawerCloseHandler}
        options={{
          groupOptions: organizationOptions.groupsOptions,
          scopeOptions: organizationOptions.scopesOptions,
          serviceOptions: organizationOptions.servicesOptions,
          emailOptions: organizationOptions.emailOptionList,
        }}
      />
    );
  };

  const renderSendReportAlerts = () => {
    if (sendReportError) {
      return (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity="error">
            {`${translation(`settings.details.sendingReportError`)}: ${
              (sendReportError as any)?.data?.message
            }`}
          </Alert>
        </CommonSnackbarContainer>
      );
    }

    if (sendReportRuleLoading || sendReportSuccess) {
      return (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity="info">
            {`${translation(`settings.details.sendingReportSuccess`)}`}
          </Alert>
        </CommonSnackbarContainer>
      );
    }

    return <></>;
  };
  const handlePopoverClose = () => {
    formChanged ? setFormCancelationModalOpen(true) : drawerCloseHandler();
  };

  if (!hasReportRuleReadPermission) return <ForbiddenPage />;

  return (
    <>
      <SettingsLayout<ReportRule>
        headerProps={{
          setTableItems: setTableItems,
          items: reportRules || [],
          filterPropertyName: 'name',
          onButtonClick: drawerOpenHandler,
          buttonRequiredPermissions: [
            ApplicationPermission.SETTINGS_REPORTS_RULES_WRITE,
          ],
          buttonLabel: (
            <FormattedMessage
              id="settings.details.addNewReportRule"
              defaultMessage="Add New Report Rule"
            />
          ),
          buttonType: 'button',
        }}
        tableProps={{
          rowData: tableItems || reportRules,
          columnDefs: notificationRulesColumns,
          isLoading: reportRulesLoading,
          onItemClick: (item) => rowClickHandler(item),
          deleteMutation: useDeleteNotificationRuleMutation,
          ignoreRowClick: shouldIgnoreRowClick,
        }}
        fetchData={() => {}}
        components={{
          ButtonComponent: (
            <Button
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleMenuPopoverClick}
              className="opus-primary-button settings-header-button"
            >
              Add Report
            </Button>
          ),
        }}
      />

      <Popover
        id={id}
        open={menuPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleMenuPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: 'report-rule-type-selection-popover' }}
      >
        <MenuList>
          <MenuItem
            onClick={() =>
              drawerOpenHandler(ReportRuleCreationType.COMPARATIVE)
            }
          >
            <span>Comparative Report</span>
            <CommonTooltip
              title={
                <span className="campaign-live-tooltip-text">
                  {translation('reportRule.comparative.titleTooltip')}
                </span>
              }
              placement="top"
            >
              <span>
                <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
              </span>
            </CommonTooltip>
          </MenuItem>
          <MenuItem
            onClick={() => drawerOpenHandler(ReportRuleCreationType.SECURITY)}
          >
            <span>Security Report</span>
            <CommonTooltip
              title={
                <span className="campaign-live-tooltip-text">
                  {translation('reportRule.security.titleTooltip')}
                </span>
              }
              placement="top"
            >
              <span>
                <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
              </span>
            </CommonTooltip>
          </MenuItem>
        </MenuList>
      </Popover>
      <PopoverWrapper
        showPopover={popoverOpen}
        handleClose={handlePopoverClose}
      >
        {renderDrawerContent()}
        {/* <ReportRuleDrawerPanel
          onCancel={drawerCloseHandler}
          detectFormChanged={(isChanged: boolean) => setFormChanged(isChanged)}
          cancelationModalProps={{
            onClose: () => {
              setFormCancelationModalOpen(false);
            },
            onOpen: () => {
              setFormCancelationModalOpen(true);
            },
            open: formCancelationModalOpen,
          }}
        /> */}
      </PopoverWrapper>
      {renderSendReportAlerts()}
    </>
  );
};
