export enum NotificationRuleFrequency {
  '10_MINUTE' = '10',
  '30_MINUTE' = '30',
  '45_MINUTE' = '45',
  '60_MINUTE' = '60',
  '120_MINUTE' = '120',
  CUSTOM = 'custom',
}
export const NotificationRuleFrequencyList = [
  {
    value: NotificationRuleFrequency['10_MINUTE'],
    label: 'Every 10 minutes',
  },
  {
    value: NotificationRuleFrequency['30_MINUTE'],
    label: 'Every 30 minutes',
  },
  {
    value: NotificationRuleFrequency['45_MINUTE'],
    label: 'Every 45 minutes',
  },
  {
    value: NotificationRuleFrequency['60_MINUTE'],
    label: 'Every 60 minutes',
  },
  {
    value: NotificationRuleFrequency['120_MINUTE'],
    label: 'Every 120 minutes',
  },
  {
    value: NotificationRuleFrequency.CUSTOM,
    label: 'Once per day at ...',
  },
];
