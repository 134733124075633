import { AutocompleteOption } from 'FindingDetails/store/api';
import * as yup from 'yup';

export enum ReportRuleStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum ReportRulePropertyType {
  SCOPE = 'Scope',
  GROUP = 'Group',
  SERVICE = 'Service',
  DOMAIN = 'Domain',
}

export enum ReportRuleRecipientType {
  EMAIL = 'Email',
  GROUP_OWNERS = 'Group Owners',
  SERVICE_OWNERS = 'Service Owners',
}

export enum ReportRulePropertyOperator {
  IN = 'IN',
}

export enum ReportRuleActionType {
  EMAIL = 'EMAIL',
}

export enum ReportRuleContentGroupType {
  RISK = 'RISK',
  COMPARATIVE_PER_SERVICE = 'COMPARATIVE_PER_SERVICE',
  COMPARATIVE_PER_GROUP = 'COMPARATIVE_PER_GROUP',
}

export enum ReportRuleContentWidgetId {
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_STATUS = 'accumulative_open_findings_by_sla_status',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SEVERITY = 'accumulative_open_findings_by_severity',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_BREACH_AGE = 'accumulative_open_findings_by_sla_breach_age',
  TOTAL_METRICS = 'total_metrics',
  TRENDS_METRICS = 'trends_metrics',
  TRENDS_GRAPH = 'trends_graph',
  FINDING_BY_STATUS = 'finding_by_status',
  FINDING_BY_SEVERITY = 'finding_by_severity',
  FINDING_BY_CATEGORY = 'finding_by_category',
  TOP_ASSIGNEES = 'top_assignees',
  FINDING_BY_TICKET_STATUS = 'finding_by_ticket_status',
  FINDING_BY_SLA_BREACH_AGE = 'finding_by_sla_breach_age',
  ACTIVE_CAMPAIGNS = 'active_campaigns',
}

export const operationalOverviewWidgetIds = [
  ReportRuleContentWidgetId.FINDING_BY_STATUS,
  ReportRuleContentWidgetId.TOP_ASSIGNEES,
  ReportRuleContentWidgetId.FINDING_BY_SLA_BREACH_AGE,
  ReportRuleContentWidgetId.FINDING_BY_TICKET_STATUS,
];

export enum ReportRuleFrequencyType {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  QUARTERS = 'QUARTERS',
  ONE_TIME = 'ONE_TIME',
}

export enum ReportRuleType {
  STATIC = 'static',
  GROUP_OWNERS = 'group-owners',
  SERVICE_OWNERS = 'service-owners',
}

export const OrganizationSelectionRequiredRuleTypes = [
  ReportRuleType.GROUP_OWNERS,
  ReportRuleType.SERVICE_OWNERS,
];

export interface ReportRuleEmailActionData {
  recipients?: Array<string>;
  roles?: Array<string>;
}

export type ReportRuleActionData = ReportRuleEmailActionData;

export interface ReportRuleProperty {
  type: ReportRulePropertyType;
  operator: ReportRulePropertyOperator;
  values: any[];
}

export interface ReportRuleAction {
  type: ReportRuleActionType;
  data?: ReportRuleActionData;
}

export interface ReportRuleContentGroupElement {
  id: string;
  label?: string;
}

export interface ReportRuleContentGroupConfiguration {
  timeFrame: any;
  trendMetricsTypes: Array<any>;
}

export interface ReportRuleContentGroup {
  type: ReportRuleContentGroupType;
  label: string;
  elements: Array<ReportRuleContentGroupElement>;
  configuration?: ReportRuleContentGroupConfiguration;
}

export interface ReportRule {
  id?: string;
  tenantId?: string;
  name: string;
  description?: string;
  type: ReportRuleType;
  status: ReportRuleStatus;
  properties: Array<ReportRuleProperty>;
  actions: Array<ReportRuleAction>;
  contentGroups: Array<ReportRuleContentGroup>;
  frequencyType: ReportRuleFrequencyType;
  frequencyUnit?: number;
}

export interface ReportRuleFormData {
  name: string;
  description: string;
  recipients: AutocompleteOption;
  contentType: AutocompleteOption;
  widgets: Array<AutocompleteOption>;
  scopes: AutocompleteOption;
  groups: AutocompleteOption | Array<AutocompleteOption>;
  services: Array<AutocompleteOption>;
  emails: Array<AutocompleteOption>;
  domains: Array<AutocompleteOption>;
  frequencyType: AutocompleteOption;
  timeFrame?: AutocompleteOption;
  trendMetricsTypes?: Array<AutocompleteOption>;
}

export const comparativeReportRuleSchema: any = {
  name: yup.string().required(),
  description: yup.string(),
  type: yup.object().required(),
  compareBy: yup.array().required().min(1),
  domains: yup.array(),
  scopes: yup.object(),
  groups: yup.array(),
  mode: yup.object().required(),
  recipients: yup.array().required().min(1),
  startingOn: yup.object(),
  frequency: yup.object().required(),
};

export const securityReportRuleSchema: any = {
  name: yup.string().required(),
  description: yup.string(),
  widgets: yup.array().required().min(1),
  metrics: yup.array().required().min(1),
  trendingChartMetrics: yup.array().required().min(1),
  timeFrame: yup.object().required(),
  domains: yup.array(),
  scopes: yup.object().required(),
  groups: yup.object().required(),
  services: yup.array(),
  mode: yup.object().required(),
  recipients: yup.array().required().min(1),
  startingOn: yup.object(),
  frequency: yup.object().required(),
};

export interface ComparativeReportRuleFormData {
  name: string;
  description?: string;
  type: AutocompleteOption;
  compareBy: Array<AutocompleteOption>;
  domains: Array<AutocompleteOption>;
  scopes?: AutocompleteOption;
  groups?: Array<AutocompleteOption>;
  mode: AutocompleteOption;
  recipients: Array<AutocompleteOption>;
  startingOn: AutocompleteOption;
  frequency: AutocompleteOption;
}

export interface SecurityReportRuleFormData {
  name: string;
  description?: string;
  widgets: Array<AutocompleteOption>;
  metrics: Array<AutocompleteOption>;
  trendingChartMetrics: Array<AutocompleteOption>;
  timeFrame: AutocompleteOption;
  domains: Array<AutocompleteOption>;
  scopes?: AutocompleteOption;
  groups?: AutocompleteOption;
  services: Array<AutocompleteOption>;
  mode: AutocompleteOption;
  recipients: Array<AutocompleteOption>;
  startingOn: AutocompleteOption;
  frequency: AutocompleteOption;
}

export interface ReportRuleModel {
  id?: string;
  name: string;
  type: ReportRuleCreationType;
  description?: string;
  scheduleSettings: ReportRuleScheduleSettings;
  recipients: Array<ReportRuleRecipient>;
  configuration: ReportRuleConfiguration;
  reportScope: ReportRuleScope;
  status?: ReportRuleStatus;
}

export enum ReportRuleType {
  SECURITY = 'security',
  COMPARATIVE = 'comparative',
}

export interface ReportRuleScheduleSettings {
  frequencyType: ReportRuleFrequencyType;
  frequencyUnit?: number;
  startTime?: Date;
}

export interface ReportRuleRecipient {
  type: 'email' | 'role';
  value: string;
}

export interface ComparisonReportConfiguration {
  comparisonObject: ComparisonReportObjects;
  comparisonFactors: Array<ComparisonReportFactor>;
}

export interface SecurityReportSelectedWidget {
  name: string;
  configuration: any;
}

export interface SecurityReportConfiguration {
  selectedWidgets: Array<SecurityReportSelectedWidget>;
}

export type ReportRuleConfiguration =
  | ComparisonReportConfiguration
  | SecurityReportConfiguration;

export enum ComparisonReportObjects {
  SCOPES = 'scopes',
  GROUPS = 'groups',
  SUB_GROUPS = 'sub-groups',
  SERVICES = 'services',
}

export enum ComparisonReportFactor {
  SEVERITY = 'severity',
  SLA_STATUS = 'sla-status',
  SLA_BREACH_AGE = 'sla-breach-age',
}

export const ComparisonReportFactorToWidgetId = {
  [ComparisonReportFactor.SEVERITY]:
    ReportRuleContentWidgetId.ACCUMULATIVE_OPEN_FINDINGS_BY_SEVERITY,
  [ComparisonReportFactor.SLA_BREACH_AGE]:
    ReportRuleContentWidgetId.ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_BREACH_AGE,
  [ComparisonReportFactor.SLA_STATUS]:
    ReportRuleContentWidgetId.ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_STATUS,
};

export class ReportRuleScope {
  domains?: string[];
  scopeIds?: string[];
  groupIds?: string[];
  serviceIds?: string[];
}

export enum ReportRuleComparisonObject {
  COMPARISON_PER_SERVICE = 'services',
  COMPARISON_PER_SUB_GROUP = 'sub-groups',
  COMPARISON_PER_GROUP = 'groups',
  COMPARISON_PER_SCOPE = 'scopes',
}

export enum ReportRuleComparisonFactor {
  OPEN_FINDINGS_BY_SLA = 'sla-status',
  OPEN_FINDINGS_BY_SEVERITY = 'severity',
  OPEN_FINDINGS_BY_SLA_BREACH_AGE = 'sla-breach-age',
}

export enum ReportRuleMode {
  ROLE_BASED_REPORT = 'role',
  STATIC_REPORT = 'email',
}

export enum ReportRuleFrequency {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  QUARTERS = 'QUARTERS',
  ONE_TIME = 'ONE_TIME',
}

export enum ReportRuleRoleRecipient {
  SCOPE_OWNERS = 'scope_owners',
  GROUP_OWNERS = 'group_owners',
  SERVICE_OWNERS = 'service_owners',
}

export enum ReportRuleCreationType {
  COMPARATIVE = 'comparative',
  SECURITY = 'security',
}

export enum ReportRuleSecurityWidget {
  ACTIVE_CAMPAIGNS = 'active_campaigns',
  FINDINGS_BY_CATEGORY = 'finding_by_category',
  FINDINGS_BY_SEVERITY = 'finding_by_severity',
  FINDINGS_BY_STATUS = 'finding_by_status',
  FINDINGS_BY_SLA_BREACH_AGE = 'finding_by_sla_breach_age',
  FINDINGS_BY_TICKET_STATUS = 'finding_by_ticket_status',
  TOP_ASSIGNEEES = 'top_assignees',
  TRENDS_GRAPH = 'trends_graph',
  TRENDS_METRICS = 'trends_metrics',
}

export const reportRuleConfigurationTypeOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleComparisonObject.COMPARISON_PER_SERVICE,
    label: 'Comparison per Service',
  },
  {
    value: ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP,
    label: 'Comparison per Sub-Group',
  },
  {
    value: ReportRuleComparisonObject.COMPARISON_PER_GROUP,
    label: 'Comparison per Group',
  },
  {
    value: ReportRuleComparisonObject.COMPARISON_PER_SCOPE,
    label: 'Comparison per Scope',
  },
];

export const reportRuleCompareByOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleComparisonFactor.OPEN_FINDINGS_BY_SLA,
    label: 'Open Findings By Sla',
  },
  {
    value: ReportRuleComparisonFactor.OPEN_FINDINGS_BY_SEVERITY,
    label: 'Open Findings By Severity',
  },
  {
    value: ReportRuleComparisonFactor.OPEN_FINDINGS_BY_SLA_BREACH_AGE,
    label: 'Open Findings By SLA Breach Age',
  },
];

export const reportRuleModeOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleMode.ROLE_BASED_REPORT,
    label: 'Role Based Report',
  },
  {
    value: ReportRuleMode.STATIC_REPORT,
    label: 'Static Report',
  },
];

export const reportRuleRecipientOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleRoleRecipient.SCOPE_OWNERS,
    label: 'Scope Owners',
  },
  {
    value: ReportRuleRoleRecipient.GROUP_OWNERS,
    label: 'Group Owners',
  },
  {
    value: ReportRuleRoleRecipient.SERVICE_OWNERS,
    label: 'Service Owners',
  },
];

export const reportRuleFrequencyOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleFrequency.ONE_TIME,
    label: 'One Time (Does not repeat)',
  },
  {
    value: ReportRuleFrequency.DAYS,
    label: 'Daily',
  },
  {
    value: ReportRuleFrequency.WEEKS,
    label: 'Weekly',
  },
  {
    value: ReportRuleFrequency.QUARTERS,
    label: 'Quarterly',
  },
];

export const reportRuleSecurityWidgetOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleSecurityWidget.ACTIVE_CAMPAIGNS,
    label: 'Active Campaigns',
  },
  {
    value: ReportRuleSecurityWidget.FINDINGS_BY_CATEGORY,
    label: 'Findings by Category',
  },
  {
    value: ReportRuleSecurityWidget.FINDINGS_BY_SEVERITY,
    label: 'Findings by Severity',
  },
  {
    value: ReportRuleSecurityWidget.FINDINGS_BY_STATUS,
    label: 'Findings by Status',
  },
  {
    value: ReportRuleSecurityWidget.FINDINGS_BY_SLA_BREACH_AGE,
    label: 'Findings by SLA Breach Age',
  },
  {
    value: ReportRuleSecurityWidget.FINDINGS_BY_TICKET_STATUS,
    label: 'Findings by Ticket Status',
  },
  {
    value: ReportRuleSecurityWidget.TOP_ASSIGNEEES,
    label: 'Top Assignees',
  },
];
