import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { defaultOrder } from 'FindingDetails/consts';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridSelectionType,
  GridType,
  RiskFilterItem,
  getGridFilterSelections,
  getGridFilterState,
  getResourceGroupTableSelectionProps,
  getRiskGroupTableSelectionProps,
  getRiskSearchParams,
  getRiskTableSelectionProps,
  getRootCauseGroupTableSelectionProps,
  resourceGroupTableSelectionDefaultProps,
  riskGroupTableSelectionDefaultProps,
  riskTableSelectionDefaultProps,
  rootCauseGroupTableDefaultSelectionProps,
  codeOriginGroupTableSelectionDefaultProps,
  setActiveGridTab,
  setGridFilterState,
  setResourceGroupTableSelection,
  setRiskGroupTableSelection,
  setRiskTableSelection,
  setRootCauseGroupTableSelection,
  getCodeOriginGroupTableSelectionProps,
  setSearchParams,
  getGridDuplicationState,
  setGridDuplicationState,
  getFilterState as getStoreFilterState,
  setFilterState,
  removeFilterItem,
  clearFilterState,
  replaceFilterState,
  getAdditionalSearchParams,
  getVulnerabilitySearchParams,
  getTagsSearchParams,
  GridSelectionProps,
  getPackageGroupTableSelectionProps,
  setPackageGroupTableSelection,
  packageGroupTableDefaultSelectionProps,
} from 'Risk/store';
import AdvanceFilter from 'shared/components/SearchFilter/AdvanceFilter';
import TabList from 'shared/components/TabList';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import FilterActions from '../FilterActions';
import RiskGrid from '../RiskGrid';
import RiskGroupGrid from '../RiskGroupGrid';
import ResourceGroupGrid from '../ResourceGroupGrid';
import {
  CodeOriginGroupGrid,
  codeOriginGroupGridDefaultSort,
} from '../CodeOriginGroupGrid/CodeOriginGroupGrid';
import {
  useCreateCustomFilterMutation,
  useFetchFilterInformationForFieldMutation,
  useGenerateFilterMutation,
} from 'Risk/store/api';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { RootCauseGroupGrid } from '../RootCauseGroupGrid/RootCauseGroupGrid';
import { AgGridEvent, RowClickedEvent } from 'ag-grid-community';
import { resourceGroupGridDefaultSort } from '../ResourceGroupGrid/ResourceGroupGrid';
import {
  packageGroupDefaultOrder,
  riskGridQuickFilters,
  riskGroupFilterItems,
  riskViewFilterCategories,
  rootCauseDefaultOrder,
} from 'shared/fixtures/data/risk-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import {
  FavouritesFilterItem,
  QuickFiltersList,
} from 'shared/components/CommonFavouritesFilter/CommonFavouritesFilter';
import { useAuthUser, useFeatureEntitlements } from '@frontegg/react';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { FindingStatus } from '../../../FindingDetails/interfaces/status';
import FindingCodeEventDrawerComplex from '../../../shared/components/FindingCodeEventDrawerComplex/FindingCodeEventDrawerComplex';
import { CustomFilterModal } from 'shared/components/CustomFilterModal/CustomFilterModal';
import { CustomFilterType } from 'Risk/interfaces/CustomFilterType.enum';
import { Snackbar, Alert, Switch, TextField, Button } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OrderState } from 'Common/utils/sort';
import { getFindingsFilterState } from 'FindingDetails/store';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { useSearchParams } from 'react-router-dom';
import EntitledContent from 'shared/components/EntitledContent';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import ImportFindingsModal from 'shared/components/ImportFindingsModal';
import {
  ApiStaticOptionSourceProperties,
  CategoryState,
  ExtendedFilterCategory,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import classNames from 'classnames';
import ResourceAssetDetailsDrawer from 'Assets/components/ResourceAssetDetailsDrawer';
import CampaignCreateModal from '../CampaignCreateModal';
import PackageGroupGrid from '../PackageGroupGrid';

const tabListItems = [
  {
    id: GridType.Risk,
    label: 'Risk',
  },
  {
    id: GridType.ResourceName,
    label: 'Resource',
  },
  {
    id: GridType.CodeOrigin,
    label: 'Code Origin',
  },
  {
    id: GridType.RootCause,
    label: 'Root Cause',
  },
  {
    id: GridType.Package,
    label: 'Package',
  },
  {
    id: GridType.None,
    label: 'None',
  },
];

interface ActionControlPayload {
  itemCount: number;
  onCancel: () => void;
  payload: any;
}

const filterSearchParamsHandler = new FilterSearchParamsHandler();
const advancedFilterDataHandler = new AdvanceFilterHandler();

interface RiskAdvancedDataGridProps extends BaseComponentProps {}

export const RiskAdvancedDataGrid: FunctionComponent<
  RiskAdvancedDataGridProps
> = () => {
  const categoryState = useCommonSelector(getStoreFilterState);

  const user = useAuthUser();
  const [openCustomFilterModal, setOpenCustomFilterModal] =
    useState<boolean>(false);
  const [openImportFindingsModal, setOpenImportFindingsModal] =
    useState<boolean>(false);

  const [openCampaignModal, setOpenCampaignModal] = useState<boolean>(false);

  const [generateFilterMessage, setGenerateFilterMessage] =
    useState<string>('');

  const [openAIFilter, setOpenAIFilter] = useState<boolean>(false);

  const permissionActions = usePermissions();

  const { isEntitled: isEntitledToAIFilters } = useFeatureEntitlements(
    SystemFeatureFlag.AI_FILTERS
  );

  const dispatch = useCommonDispatch();
  const { t: translation } = useTranslation();
  let [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const riskSearchParams = useCommonSelector(getRiskSearchParams);
  const isDuplicatedStateEnabled = useCommonSelector(getGridDuplicationState);
  const findingsFilterState = useCommonSelector(getFindingsFilterState);

  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const tagsSearchParams = useCommonSelector(getTagsSearchParams);

  const [
    generateFilter,
    {
      isLoading: generateFilterLoading,
      data: generateFilterData,
      reset: generateFilterDataReset,
    },
  ] = useGenerateFilterMutation();

  const generateFilterErrorText = useMemo<string>(() => {
    return generateFilterData?.text || '';
  }, [generateFilterData]);

  const [
    createCustomFilter,
    {
      isSuccess: customFilterCreatedSuccessfully,
      isError: customFilterFailed,
      reset: resetCreateCustomFilter,
    },
  ] = useCreateCustomFilterMutation();

  const [
    fetchFindingsWithResource,
    { isLoading: isLoadingCSVDowlonad, reset: resetFetchFindings },
  ] = useFetchFindingsWithResourceMutation();

  const gridRef = useRef<AgGridEvent | null>(null);

  const hasExceptionsReadPermission = permissionActions.includes(
    ApplicationPermission.EXCEPTIONS_READ
  );

  const riskGroupGridSelectionProps = useCommonSelector(
    getRiskGroupTableSelectionProps
  );

  const resourceGroupGridSelectionProps = useCommonSelector(
    getResourceGroupTableSelectionProps
  );

  const codeOriginGroupGridSelectionProps = useCommonSelector(
    getCodeOriginGroupTableSelectionProps
  );

  const rootCauseGroupGridSelectionProps = useCommonSelector(
    getRootCauseGroupTableSelectionProps
  );

  const packageGroupGridSelectionProps = useCommonSelector(
    getPackageGroupTableSelectionProps
  );
  const riskGridSelectionProps = useCommonSelector(getRiskTableSelectionProps);

  const activeTabId = useMemo<GridType>(() => {
    const activeGridTab: GridType = urlSearchParams.activeTabId as GridType;

    dispatch(setActiveGridTab(activeGridTab));
    return activeGridTab;
  }, [urlSearchParams]);

  const setFilterItems = useMemo(() => {
    return setGridFilterState;
  }, [activeTabId]);

  const getFilterState = useMemo(() => {
    return getGridFilterState;
  }, [activeTabId]);

  const getFilterSelections = useMemo(() => {
    return getGridFilterSelections;
  }, [activeTabId]);

  const filterState = useCommonSelector(getGridFilterState);

  const filterSelections = useCommonSelector(getGridFilterSelections);

  const assignedToMeQuickFilter: FavouritesFilterItem = {
    name: translation('common.quickFilters.assignedToMe', {
      param1: translation('findings.title'),
    }),
    id: 'assignee',
    options: [
      {
        type: 'assigneeUserId',
        values: [
          {
            id: user.id,
            name: user.name,
          },
        ],
      },
    ],
  };

  const ownedByMeQuickFilter: FavouritesFilterItem = {
    name: translation('common.quickFilters.ownedByMe', {
      param1: translation('findings.title'),
    }),
    id: 'owner',
    options: [
      {
        type: 'ownerUserId',
        values: [
          {
            id: user.email,
            name: user.email,
          },
        ],
      },
    ],
  };

  const activeWithoutTicketQuickFilter: FavouritesFilterItem = {
    id: 'open_findings_without_ticket',
    name: 'Open Findings without Ticket',
    options: [
      {
        type: 'status',
        values: [
          {
            id: FindingStatus.OPEN,
            name: FindingStatus.OPEN,
          },
          {
            id: FindingStatus.IN_PROGRESS,
            name: FindingStatus.IN_PROGRESS,
          },
          {
            id: FindingStatus.IN_REVIEW,
            name: FindingStatus.IN_REVIEW,
          },
        ],
      },
      {
        type: 'itemRelationMetadata',
        values: [
          {
            id: false,
            name: 'No',
          },
        ],
      },
    ],
  };

  const quickFilterItems = useMemo<QuickFiltersList>(() => {
    if (hasExceptionsReadPermission)
      return {
        savedFilters: [],
        systemFilters: [
          assignedToMeQuickFilter,
          ownedByMeQuickFilter,
          activeWithoutTicketQuickFilter,
          ...riskGridQuickFilters,
        ],
      };

    return {
      savedFilters: [],
      systemFilters: [assignedToMeQuickFilter, activeWithoutTicketQuickFilter],
    };
  }, [user, hasExceptionsReadPermission]);

  const generateFiltersHandler = () => {
    const generatedFilter = {
      ...(generateFilterData?.search_findings_filters?.filter || {}),
      ...(generateFilterData?.search_findings_filters?.vulnerabilityFilter ||
        {}),
    };

    const allCategories = advancedFilterDataHandler.extractAllCategories(
      riskViewFilterCategories
    );

    const filterState =
      advancedFilterDataHandler.translateApiFiltersToToFilterStateSelections(
        generatedFilter,
        allCategories
      );

    dispatch(replaceFilterState({ updatedState: filterState }));
  };

  useEffect(() => {
    if (generateFilterData?.search_findings_filters) {
      generateFiltersHandler();
    }
  }, [generateFilterData]);

  useEffect(() => {
    if (generateFilterData) {
      setTimeout(() => {
        generateFilterDataReset();
      }, 5000);
    }
  }, [generateFilterData]);

  useEffect(() => {
    if (!urlSearchParams.activeTabId) {
      setUrlSearchParams({
        activeTabId: GridType.Risk,
      });
    } else {
      if (
        (urlSearchParams.activeTabId === GridType.None ||
          urlSearchParams.activeTabId === GridType.RootCause) &&
        !urlSearchParams.order
      ) {
        const existingParams = filterSearchParamsHandler.setSearchParamsForKeys(
          urlSearchParams,
          ['filter', 'activeTabId', 'searchKeyword']
        );

        const order =
          urlSearchParams.activeTabId === GridType.RootCause
            ? rootCauseDefaultOrder
            : defaultOrder;

        setUrlSearchParams({
          ...existingParams,
          order: JSON.stringify(order),
        });
      }
    }
  }, []);

  useEffect(() => {
    const queryFilters =
      advancedFilterDataHandler.translateFilterStateSelectionsToQueryFilters(
        categoryState,
        riskViewFilterCategories
      );

    if (Object.keys(queryFilters)) {
      const currentSearchParams = getUrlSearchParams();
      const updatedSearchParams = {
        ...currentSearchParams,
        filter: JSON.stringify(queryFilters),
      };

      setReactiveSearchParams(updatedSearchParams);
    }
  }, [categoryState]);

  const searchProperty = useMemo<string>(() => {
    switch (activeTabId) {
      case GridType.ResourceName:
        return 'resourceName';
      case GridType.CodeOrigin:
        return 'codeOrigin';
      default:
        return 'title';
    }
  }, [activeTabId]);

  const searchValue = useMemo<string>(() => {
    switch (activeTabId) {
      case GridType.ResourceName:
        return (
          (riskSearchParams?.filter?.resourceName as RiskFilterItem)?.value ||
          ''
        );
      case GridType.CodeOrigin:
        return (
          (riskSearchParams?.filter?.codeOrigin as RiskFilterItem)?.value || ''
        );
      default:
        return (riskSearchParams?.filter?.title as RiskFilterItem)?.value || '';
    }
  }, [activeTabId, riskSearchParams?.filter]);

  useEffect(() => {
    let updatedFilterParams = {
      ...riskSearchParams.filter,
    };

    if (updatedFilterParams.resourceName)
      delete updatedFilterParams.resourceName;
    if (updatedFilterParams.codeOrigin) delete updatedFilterParams.codeOrigin;
    if (updatedFilterParams.title) delete updatedFilterParams.title;

    if (urlSearchParams.searchKeyword) {
      updatedFilterParams = {
        ...updatedFilterParams,
        [searchProperty]: {
          value: urlSearchParams.searchKeyword,
        },
      };
    }

    dispatch(
      setSearchParams({
        ...riskSearchParams,
        filter: updatedFilterParams,
      })
    );
  }, [searchProperty, urlSearchParams?.searchKeyword]);

  const cancelRiskTypesSelection = () => {
    dispatch(
      setRiskGroupTableSelection({
        ...riskGroupTableSelectionDefaultProps,
        availableChildren: riskGroupGridSelectionProps.availableChildren,
        rows: riskGroupGridSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };

  const cancelPackageSelection = () => {
    dispatch(
      setPackageGroupTableSelection({
        ...packageGroupTableDefaultSelectionProps,
        availableChildren: packageGroupGridSelectionProps.availableChildren,
        rows: packageGroupGridSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };

  const cancelRisksSelection = () => {
    dispatch(
      setRiskTableSelection({
        ...riskTableSelectionDefaultProps,
        availableChildren: riskGridSelectionProps.availableChildren,
        rows: riskGridSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };

  const cancelRiskByResourceSelection = () => {
    dispatch(
      setResourceGroupTableSelection({
        ...resourceGroupTableSelectionDefaultProps,
        availableChildren: resourceGroupGridSelectionProps.availableChildren,
        rows: resourceGroupGridSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };
  const cancelRiskByCodeOriginSelection = () => {
    dispatch(
      setResourceGroupTableSelection({
        ...codeOriginGroupTableSelectionDefaultProps,
        availableChildren: codeOriginGroupGridSelectionProps.availableChildren,
        rows: codeOriginGroupGridSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };

  const cancelRootCauseGridSelection = () => {
    dispatch(
      setRootCauseGroupTableSelection({
        ...rootCauseGroupTableDefaultSelectionProps,
        availableChildren: rootCauseGroupGridSelectionProps.availableChildren,
        rows: rootCauseGroupGridSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };

  useEffect(() => {
    if (filterSelections.length) {
      const searchParams = getUrlSearchParams();

      if (!searchParams.filter) {
        const filterQuery = JSON.stringify(
          advancedFilterDataHandler.translateAdvanceFilterComponentValuesToUrlSearchParams(
            filterSelections
          )
        );

        setUrlSearchParams({
          ...searchParams,
          filter: filterQuery,
        });
      }
    }
  }, [filterSelections]);

  const clearFiltersFromStore = () => {
    const updatedArray = filterState.map((obj: any) => {
      if (!obj?.dateRange) {
        return {
          ...obj,
          dateRange: null,
          items: obj.items.map((item: any) => {
            return { ...item, checked: false };
          }),
        };
      }
      return {
        ...obj,
        dateRange: null,
      };
    });
    dispatch(setFilterItems({ list: updatedArray, isSelectingItem: true }));
    dispatch(setSearchParams({ ...riskSearchParams, filter: {} }));
  };

  const clearFiltersFromUrl = () => {
    const existingUrlSearchParams =
      filterSearchParamsHandler.setSearchParamsForKeys({}, []);

    setUrlSearchParams({
      activeTabId: existingUrlSearchParams.activeTabId,
      order: existingUrlSearchParams.order,
    });
  };

  const clearFilters = () => {
    clearFiltersFromStore();
    clearFiltersFromUrl();
  };

  useEffect(() => {
    return () => {
      clearFiltersFromStore();
    };
  }, []);

  const actionControlPayload = useMemo<ActionControlPayload>(() => {
    switch (activeTabId) {
      case GridType.Risk: {
        return {
          itemCount: riskGroupGridSelectionProps.totalSelectedChildren,
          onCancel: cancelRiskTypesSelection,
          payload: {
            ...riskGroupGridSelectionProps,
            gridType: GridType.Risk,
          },
        };
      }
      case GridType.ResourceName: {
        return {
          itemCount: resourceGroupGridSelectionProps.totalSelectedChildren,
          onCancel: cancelRiskByResourceSelection,
          payload: {
            ...resourceGroupGridSelectionProps,
            gridType: GridType.ResourceName,
          },
        };
      }
      case GridType.CodeOrigin: {
        return {
          itemCount: codeOriginGroupGridSelectionProps.totalSelectedChildren,
          onCancel: cancelRiskByCodeOriginSelection,
          payload: {
            ...codeOriginGroupGridSelectionProps,
            gridType: GridType.CodeOrigin,
          },
        };
      }
      case GridType.RootCause: {
        return {
          itemCount: rootCauseGroupGridSelectionProps.totalSelectedChildren,
          onCancel: cancelRootCauseGridSelection,
          payload: {
            ...rootCauseGroupGridSelectionProps,
            gridType: GridType.RootCause,
          },
        };
      }
      case GridType.Package: {
        return {
          itemCount: packageGroupGridSelectionProps.totalSelectedChildren,
          onCancel: cancelPackageSelection,
          payload: {
            ...packageGroupGridSelectionProps,
            gridType: GridType.Package,
          },
        };
      }
      case GridType.None: {
        return {
          itemCount: riskGridSelectionProps.totalSelectedChildren,
          onCancel: cancelRisksSelection,
          payload: {
            ...riskGridSelectionProps,
            gridType: GridType.None,
          },
        };
      }

      default: {
        return {
          itemCount: riskGroupGridSelectionProps.totalSelectedChildren,
          onCancel: () => {},
          payload: {},
        };
      }
    }
  }, [
    riskGroupGridSelectionProps,
    riskGridSelectionProps,
    codeOriginGroupGridSelectionProps,
    rootCauseGroupGridSelectionProps,
    resourceGroupGridSelectionProps,
    packageGroupGridSelectionProps,
    activeTabId,
  ]);

  const onSearch = useCallback(
    (searchInput: string) => {
      let existingParams = filterSearchParamsHandler.setSearchParamsForKeys(
        urlSearchParams,
        ['filter', 'activeTabId', 'order', 'searchKeyword']
      );

      if (searchInput.length) {
        existingParams = {
          ...existingParams,
          searchKeyword: searchInput,
        };
      } else {
        delete existingParams.searchKeyword;
      }

      setUrlSearchParams(existingParams);
    },
    [activeTabId]
  );

  const handleFindingDetailsModalOpen = (findingId: string) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openFindingId', findingId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const handleRowClicked = (event: RowClickedEvent) => {
    handleFindingDetailsModalOpen(event.data.findingId);
  };

  const handleRootCauseRowClicked = (rootCauseId: string) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openCodeEventId', rootCauseId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const handleRootCauseGridRowClicked = (event: RowClickedEvent) => {
    if (event.data?.rootCauseMetadata) {
      const rootCauseId = event.data?.rootCauseId;

      handleRootCauseRowClicked(rootCauseId);
    } else {
      const findingId = event.data.findingIds[0];
      handleFindingDetailsModalOpen(findingId);
    }
  };

  const handlePackageGroupGridRowClicked = (event: RowClickedEvent) => {
    handleFindingDetailsModalOpen(event.data.findingId);
  };

  const icons = useMemo(() => {
    return {
      sortAscending: '<i class="fa fa-long-arrow-alt-down"/>',
      sortDescending: '<i class="fa fa-long-arrow-alt-up"/>',
    };
  }, []);

  const renderTableForTab = () => {
    switch (activeTabId) {
      case GridType.Risk:
        return (
          <RiskGroupGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case GridType.ResourceName:
        return (
          <ResourceGroupGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case GridType.CodeOrigin:
        return (
          <CodeOriginGroupGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case GridType.None:
        return (
          <RiskGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case GridType.RootCause:
        return (
          <RootCauseGroupGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRootCauseGridRowClicked}
          />
        );
      case GridType.Package:
        return (
          <PackageGroupGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handlePackageGroupGridRowClicked}
          />
        );
      default:
        if (!urlSearchParams.activeTabId) {
          setUrlSearchParams({
            activeTabId: GridType.Risk,
          });
        }
        return (
          <RiskGroupGrid
            clearFilters={clearFilters}
            onRowClicked={handleRowClicked}
          />
        );
    }
  };

  const selectGridTab = (activeItemId: GridType) => {
    let updatedParams: any = {};

    const existingParams: any = getUrlSearchParams();

    if (existingParams.filter?.length) {
      updatedParams.filter = existingParams.filter;
    }

    if (activeItemId === GridType.None) {
      updatedParams.order = JSON.stringify(defaultOrder);
    }

    if (activeItemId === GridType.RootCause) {
      updatedParams.order = JSON.stringify(rootCauseDefaultOrder);
    }

    if (activeItemId === GridType.ResourceName) {
      updatedParams.order = JSON.stringify(resourceGroupGridDefaultSort);
    }
    if (activeItemId === GridType.CodeOrigin) {
      updatedParams.order = JSON.stringify(codeOriginGroupGridDefaultSort);
    }
    if (activeItemId === GridType.Package) {
      updatedParams.order = JSON.stringify(packageGroupDefaultOrder);
    }

    setUrlSearchParams({
      activeTabId: activeItemId,
      ...updatedParams,
    });
  };

  const isNoneTab = useMemo(() => {
    return urlSearchParams.activeTabId === GridType.None;
  }, [urlSearchParams.activeTabId]);

  const saveFilters = () => {
    setOpenCustomFilterModal(true);
  };

  const handleOnSave = (filterName: string) => {
    const filters = JSON.parse(getUrlSearchParams().filter as string);
    createCustomFilter({
      name: filterName,
      customFilterEntityType: CustomFilterType.FINDING_OPERATIONAL,
      filter: filters,
    });
    setOpenCustomFilterModal(false);
  };

  const changeDuplicationFlag = () => {
    dispatch(setGridDuplicationState(!isDuplicatedStateEnabled));
  };

  const additionalCampaignFilters = useMemo<Record<string, any>>(() => {
    switch (activeTabId) {
      case GridType.Risk: {
        const selectedFindingTypes =
          riskGroupGridSelectionProps.selectedChildren;

        const egligbleSelectedFindingTypes = selectedFindingTypes.filter(
          (findingType) => {
            const selectedRow = riskGroupGridSelectionProps.rows?.find(
              (row) => row.value === findingType
            );

            if (!selectedRow) {
              return true;
            }

            if (
              selectedRow &&
              selectedRow.selection === GridSelectionType.ALL
            ) {
              return true;
            }

            return false;
          }
        );

        return egligbleSelectedFindingTypes.length > 0
          ? {
              title: egligbleSelectedFindingTypes.map((findingType) => ({
                value: findingType,
              })),
            }
          : {};
      }
      case GridType.ResourceName: {
        const selectedResourceIds =
          resourceGroupGridSelectionProps.selectedChildren;

        const egligbleSelectedResourceIds = selectedResourceIds.filter(
          (resourceId) => {
            const selectedRow = resourceGroupGridSelectionProps.rows?.find(
              (row) => row.value === resourceId
            );

            if (!selectedRow) {
              return true;
            }

            if (
              selectedRow &&
              selectedRow.selection === GridSelectionType.ALL
            ) {
              return true;
            }

            return false;
          }
        );

        return egligbleSelectedResourceIds.length > 0
          ? {
              loweredResourceId: egligbleSelectedResourceIds.map(
                (resourceId) => ({
                  value: resourceId,
                })
              ),
            }
          : {};
      }
      case GridType.CodeOrigin: {
        const selectedCodeOrigins =
          codeOriginGroupGridSelectionProps.selectedChildren;

        const egligbleSelectedCodeOrigins = selectedCodeOrigins.filter(
          (codeOrigin) => {
            const selectedRow = codeOriginGroupGridSelectionProps.rows?.find(
              (row) => row.value === codeOrigin
            );

            if (!selectedRow) {
              return true;
            }

            if (
              selectedRow &&
              selectedRow.selection === GridSelectionType.ALL
            ) {
              return true;
            }

            return false;
          }
        );

        return egligbleSelectedCodeOrigins.length > 0
          ? {
              codeOrigin: egligbleSelectedCodeOrigins.map((codeOrigin) => ({
                value: codeOrigin,
              })),
            }
          : {};
      }
      case GridType.Package: {
        const selectedFindings =
          packageGroupGridSelectionProps.selectedChildren;

        const egligbleSelectedFindings = selectedFindings.filter((finding) => {
          const selectedRow = packageGroupGridSelectionProps.rows?.find(
            (row) => row.value === finding
          );

          if (!selectedRow) {
            return true;
          }

          if (selectedRow && selectedRow.selection === GridSelectionType.ALL) {
            return true;
          }

          return false;
        });

        return egligbleSelectedFindings.length > 0
          ? {
              packageName: egligbleSelectedFindings.map((finding) => ({
                value: finding,
              })),
            }
          : {};
      }
      default:
        return {};
    }
  }, [
    riskGroupGridSelectionProps,
    resourceGroupGridSelectionProps,
    codeOriginGroupGridSelectionProps,
    packageGroupGridSelectionProps,
    activeTabId,
  ]);

  const getSelectedFindingsFromGroupSelection = (
    selectionProps: GridSelectionProps
  ): Array<string> => {
    return selectionProps.rows
      ?.map((selectedRow) => {
        if (
          selectedRow.selection === GridSelectionType.PARTIAL ||
          selectedRow.selection === GridSelectionType.MANUAL
        ) {
          return selectedRow.availableChildren.filter(
            (element) => !selectedRow.unselectedChildren?.includes(element)
          );
        }

        return [];
      })
      .flat() as Array<string>;
  };

  const selectedFindingIds = useMemo<Array<string>>(() => {
    switch (activeTabId) {
      case GridType.None:
        return (riskGridSelectionProps.selectedChildren as Array<string>) || [];
      case GridType.Risk:
        return (
          getSelectedFindingsFromGroupSelection(riskGroupGridSelectionProps) ||
          []
        );
      case GridType.ResourceName:
        return (
          getSelectedFindingsFromGroupSelection(
            resourceGroupGridSelectionProps
          ) || []
        );
      case GridType.Package:
        return (
          getSelectedFindingsFromGroupSelection(
            packageGroupGridSelectionProps
          ) || []
        );

      case GridType.CodeOrigin:
        return (
          getSelectedFindingsFromGroupSelection(
            codeOriginGroupGridSelectionProps
          ) || []
        );
      default:
        return [];
    }
  }, [
    riskGridSelectionProps,
    riskGroupGridSelectionProps,
    resourceGroupGridSelectionProps,
    codeOriginGroupGridSelectionProps,
    packageGroupGridSelectionProps,
    activeTabId,
  ]);

  const displayCreateCampaign = useMemo<boolean>(() => {
    return activeTabId !== GridType.RootCause;
  }, [activeTabId]);

  const enableCreateCampaign = useMemo<boolean>(() => {
    const newRiskSearchParams = { ...additionalSearchParams };

    if (newRiskSearchParams.state) delete newRiskSearchParams.state;

    return (
      Object.keys(newRiskSearchParams || {}).length > 0 ||
      Object.keys(vulnerabilitySearchParams || {}).length > 0 ||
      Object.keys(tagsSearchParams || {}).length > 0 ||
      selectedFindingIds.length > 0 ||
      Object.keys(additionalCampaignFilters || {}).length > 0
    );
  }, [
    additionalSearchParams,
    vulnerabilitySearchParams,
    additionalCampaignFilters,
    tagsSearchParams,
    selectedFindingIds,
  ]);

  const renderAIFilter = () => {
    if (openAIFilter) {
      return (
        <div className="risk-page-ai-filter-container">
          <div className="risk-page-ai-filter-title-area">
            <div className="risk-page-ai-filter-title">
              {translation(`risk.filters.aiFiltersTitle`)}
            </div>
            <div className="risk-page-ai-filter-helper-text">
              {translation(`risk.filters.aiFiltersHelperText`)}
            </div>
          </div>

          <div className="risk-page-ai-filter-search-area">
            <TextField
              className={classNames(['filter-input filter-main-input'])}
              placeholder={translation(`common.searchPlaceholder`)}
              onChange={(e) => {
                const searchInputValue = e.target.value;
                setGenerateFilterMessage(searchInputValue);
              }}
              InputProps={{
                className: 'search-filter-input',
                startAdornment: (
                  <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
                ),
              }}
              classes={{
                root: generateFilterErrorText.length
                  ? 'input-validation-error-1'
                  : '',
              }}
            />

            <Button
              onClick={() => {
                if (generateFilterMessage.length)
                  generateFilter(generateFilterMessage);
              }}
              className="opus-primary-button"
            >
              {generateFilterLoading ? 'Searching..' : 'Search'}
            </Button>
          </div>
        </div>
      );
    }

    return <></>;
  };

  const getOrderParams = () => {
    const searchParams = getUrlSearchParams();
    if (searchParams.order) {
      const parsedOrderParams = JSON.parse(
        searchParams.order as string
      ) as OrderState;

      return parsedOrderParams;
    }

    return null;
  };

  const exportFindings = () => {
    fetchFindingsWithResource({
      filter: {
        ...additionalSearchParams,
        ...riskSearchParams?.filter,
      },
      removeDuplications: isDuplicatedStateEnabled,
      order: getOrderParams() as OrderState,
      take: findingsFilterState.pageSize,
      skip:
        findingsFilterState.page && findingsFilterState.pageSize
          ? (findingsFilterState.page - 1) * findingsFilterState.pageSize
          : 0,
      vulnerabilityFilter: vulnerabilitySearchParams,
      tagFilter: tagsSearchParams,
      headers: 'text/csv',
    });
  };

  const enrichCategoryGroupItem = (
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> => {
    const selectedScopeOption = categoryState['scopeId'] as SingleSelectState;

    const selectedScopeId = selectedScopeOption?.selectedOptions
      ? selectedScopeOption?.selectedOptions[0]?.value
      : undefined;

    if (selectedScopeId) {
      return categories.map((category: ExtendedFilterCategory) => {
        if (category.id === 'organization') {
          return {
            ...category,
            categories: category.categories?.map(
              (subCategory: ExtendedFilterCategory) => {
                if (subCategory.id === 'groupId') {
                  return {
                    ...subCategory,
                    state: {
                      ...subCategory.state,
                      source: {
                        ...(subCategory.state as SingleSelectState).source,
                        body: {
                          ...((
                            (subCategory.state as SingleSelectState)
                              .source as ApiStaticOptionSourceProperties
                          ).body || {}),
                          startNodeId: selectedScopeId,
                        },
                      },
                    },
                  };
                }
                return subCategory;
              }
            ),
          };
        }

        return category;
      });
    }

    return categories;
  };

  enrichCategoryGroupItem(riskViewFilterCategories);

  const renderCreateCampaignButton = () => {
    if (displayCreateCampaign)
      return (
        <Button
          className="campaign-btn clickable-item"
          onClick={() => setOpenCampaignModal(true)}
          disabled={!enableCreateCampaign}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_ICON} />
          <p className="header-8">Create Campaign</p>
        </Button>
      );

    return <></>;
  };

  return (
    <div className="risk-page-body-container">
      <div className="risk-page-body-control-row">
        <TabList
          items={tabListItems}
          activeItemId={activeTabId}
          onSelect={selectGridTab}
        />
        <div className="actions-list">
          <EntitledContent flag={SystemFeatureFlag.DEDUPLICATION_TOGGLE}>
            <div className="duplication-toggle">
              <p className="label-1">
                <FormattedMessage
                  id="common.groupResourceCve"
                  defaultMessage="Group CVEs Duplications"
                ></FormattedMessage>
              </p>

              <Switch
                classes={{
                  root: 'opus-switch-field-1',
                }}
                defaultChecked={isDuplicatedStateEnabled}
                checked={isDuplicatedStateEnabled}
                onClick={changeDuplicationFlag}
              />
            </div>
          </EntitledContent>
          {renderCreateCampaignButton()}
          <EntitledContent flag={SystemFeatureFlag.FINDINGS_IMPORT_FILE}>
            <div
              className="import-btn clickable-item"
              onClick={() => setOpenImportFindingsModal(true)}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.FILE_IMPORT_ICON} />
              <p className="header-8">
                <FormattedMessage
                  id="common.import"
                  defaultMessage="Import"
                ></FormattedMessage>
              </p>
            </div>
          </EntitledContent>

          <EntitledContent flag={SystemFeatureFlag.RISK_VIEW_CSV_EXPORT}>
            {isNoneTab ? (
              <div
                className="csv-export clickable-item"
                onClick={exportFindings}
              >
                <OpusSvgIcon type={SVG_ICON_TYPES.FILE_CSV_ICON} />
                <p className="header-8">
                  <FormattedMessage
                    id="common.exportCsv"
                    defaultMessage="Export Csv"
                  ></FormattedMessage>
                </p>
              </div>
            ) : (
              <></>
            )}
          </EntitledContent>
        </div>
      </div>
      <div className="risk-page-body-filter-row">
        <AdvanceFilter
          customFilterType={CustomFilterType.FINDING_OPERATIONAL}
          saveFilters={saveFilters}
          onSearch={onSearch}
          searchValue={searchValue}
          mutationProps={{
            params: {},
            fetchMutation: useFetchFilterInformationForFieldMutation,
          }}
          quickFilterItems={quickFilterItems}
          initialFilterItems={riskGroupFilterItems}
          setListFilter={setFilterItems}
          getFilterList={getFilterState}
          categories={enrichCategoryGroupItem(riskViewFilterCategories)}
          categoryState={categoryState}
          onChange={(categoryId: string, state: CategoryState) => {
            dispatch(
              setFilterState({
                categoryId,
                state,
              })
            );
          }}
          onRemoveChip={(categoryId: string) => {
            dispatch(
              removeFilterItem({
                categoryId,
              })
            );
          }}
          clearFilters={() => {
            dispatch(clearFilterState());
          }}
          replaceFilterState={(state: Record<string, CategoryState>) => {
            dispatch(replaceFilterState({ updatedState: state }));
          }}
          aiFilterEnabled={isEntitledToAIFilters}
          toggleAIFilter={() => {
            setOpenAIFilter((prevOpenAIFilter) => !prevOpenAIFilter);
          }}
          aiFilterOpen={openAIFilter}
        />
      </div>

      {renderAIFilter()}

      {actionControlPayload.itemCount ? (
        <div className="risk-page-actions-container">
          <FilterActions
            onCancel={() => {
              if (gridRef?.current) {
                gridRef?.current?.api?.deselectAll();
              }
              actionControlPayload.onCancel();
            }}
            tagFilter={tagsSearchParams}
            genericFilter={additionalSearchParams}
            vulnerabilityFilter={vulnerabilitySearchParams}
            itemCount={actionControlPayload.itemCount}
            selectionProps={actionControlPayload.payload}
            getSelectionList={getFilterSelections}
          />
        </div>
      ) : (
        <></>
      )}

      <div className="risk-page-body-table">{renderTableForTab()}</div>
      <FindingCodeEventDrawerComplex />
      <ResourceAssetDetailsDrawer />
      <CustomFilterModal
        handleModalClose={() => {
          setOpenCustomFilterModal(false);
        }}
        onSave={handleOnSave}
        open={openCustomFilterModal}
      />

      <Snackbar
        open={Boolean(generateFilterErrorText.length)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="info">{generateFilterErrorText}</Alert>
      </Snackbar>

      {(customFilterCreatedSuccessfully || customFilterFailed) && (
        <Snackbar
          open={customFilterCreatedSuccessfully || customFilterFailed}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={() => resetCreateCustomFilter()}
          className="risk-view-snackbar"
        >
          <Alert
            severity={customFilterCreatedSuccessfully ? 'success' : 'error'}
            sx={{ minWidth: '100px !important' }}
          >
            <FormattedMessage
              id={
                customFilterCreatedSuccessfully
                  ? 'findings.filter.filterCreated'
                  : 'findings.filter.filterCreatedFailed'
              }
              defaultMessage={
                customFilterCreatedSuccessfully
                  ? 'Filter is successfully saved'
                  : 'Filter saving failed'
              }
            />
          </Alert>
        </Snackbar>
      )}
      <Snackbar
        open={isLoadingCSVDowlonad}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          resetFetchFindings();
        }}
        className="risk-view-snackbar"
      >
        <Alert severity="info">
          <FormattedMessage
            id="common.csvDownloadInfo"
            defaultMessage="CSV download may require some time to complete."
          />
        </Alert>
      </Snackbar>
      <ImportFindingsModal
        open={openImportFindingsModal}
        onClose={() => {
          setOpenImportFindingsModal(false);
        }}
        title={undefined}
        children={undefined}
      ></ImportFindingsModal>
      <CampaignCreateModal
        modalOpen={openCampaignModal}
        handleCampaignCreation={() => {}}
        handleModalClose={() => {
          setOpenCampaignModal(false);
        }}
        selectedFindingIds={selectedFindingIds}
        additionalFilters={additionalCampaignFilters}
        disableLiveCampaign={
          selectedFindingIds.length > 0 &&
          Object.keys(additionalCampaignFilters).length === 0
        }
      />
    </div>
  );
};
