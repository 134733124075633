export enum SSVCFlowTypes {
  EXPLOITATION = 'exploitationStatus',
  AUTOMATABLE = 'automatable',
  TECHNICAL_IMPACT = 'technicalImpact',
  MISSION_AND_WELL_BEING = 'missionAndWellBeingImpactValue',
  DECISION = 'decision',
}

export enum SsvcDecision {
  ACT = 'Act',
  ATTEND = 'Attend',
  TRACK_ASTRIX = 'Track*',
  TRACK = 'Track',
}

export enum SsvcPublicWellbeingImpact {
  MINIMAL = 'minimal',
  MATERIAL = 'material',
  IRREVERSIBLE = 'irreversible',
}
