import { allOption } from 'Common/components/Autocomplete/Autocomplete';
import { AutocompleteOption } from 'FindingDetails/store/api';
import _ from 'lodash';
import {
  operationalDashboardFilterOptions,
  operationalDashboardTrendMetricsTypeOptions,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import {
  reportRuleFrequencyOptions,
  reportRuleRecipientsOptions,
} from 'shared/fixtures/data/report-rule.data';
import {
  ComparativeReportRuleFormData,
  ComparisonReportConfiguration,
  ComparisonReportFactor,
  ComparisonReportObjects,
  ReportRule,
  ReportRuleActionType,
  reportRuleCompareByOptions,
  reportRuleConfigurationTypeOptions,
  ReportRuleContentGroupType,
  ReportRuleCreationType,
  ReportRuleFormData,
  ReportRuleFrequencyType,
  ReportRuleMode,
  ReportRuleModel,
  reportRuleModeOptions,
  ReportRulePropertyOperator,
  ReportRulePropertyType,
  reportRuleRecipientOptions,
  ReportRuleSecurityWidget,
  reportRuleSecurityWidgetOptions,
  ReportRuleStatus,
  ReportRuleType,
  SecurityReportConfiguration,
  SecurityReportRuleFormData,
} from 'shared/models/data/report-rule.model';

export class ReportRuleDataHandler {
  transformReportRuleFormDataToPostData(
    formData: ReportRuleFormData
  ): ReportRule {
    let groups = [];

    const formGroups = formData.groups;

    if (Array.isArray(formGroups)) {
      groups = [...formGroups];
    } else {
      groups.push(formGroups);
    }

    return {
      name: formData.name,
      description: formData.description,
      actions: [
        {
          type: ReportRuleActionType.EMAIL,
          data: {
            recipients: formData.emails?.map(
              (emailOption) => emailOption.value
            ),
          },
        },
      ],
      contentGroups: [
        {
          type: formData.contentType.value as ReportRuleContentGroupType,
          label: formData.contentType.label as string,
          elements: [
            ...formData.widgets.map((widgetOption) => ({
              id: widgetOption.value,
              label: widgetOption.label,
            })),
          ],

          configuration:
            formData.contentType.value === ReportRuleContentGroupType.RISK &&
            formData.timeFrame &&
            formData.trendMetricsTypes
              ? {
                  timeFrame: formData.timeFrame,
                  trendMetricsTypes: formData.trendMetricsTypes,
                }
              : undefined,
        },
      ],
      frequencyType: formData.frequencyType?.value as ReportRuleFrequencyType,
      properties: [
        {
          type: ReportRulePropertyType.SCOPE,
          values: [formData.scopes],
          operator: ReportRulePropertyOperator.IN,
        },
        {
          type: ReportRulePropertyType.GROUP,
          values: groups,
          operator: ReportRulePropertyOperator.IN,
        },
        {
          type: ReportRulePropertyType.SERVICE,
          values: formData.services as Array<AutocompleteOption>,
          operator: ReportRulePropertyOperator.IN,
        },
        {
          type: ReportRulePropertyType.DOMAIN,
          values: formData.domains as Array<AutocompleteOption>,
          operator: ReportRulePropertyOperator.IN,
        },
      ],
      status: ReportRuleStatus.ENABLED,
      type: formData.recipients.value as ReportRuleType,
    };
  }

  transformReportRuleToFormData(reportRule: ReportRule): ReportRuleFormData {
    const contentGroup = reportRule.contentGroups[0];
    const recipients =
      reportRuleRecipientsOptions.find(
        (option) => option.value === reportRule.type
      ) || reportRuleRecipientsOptions[0];

    const emailAction = reportRule.actions?.find(
      (action) => action.type === ReportRuleActionType.EMAIL
    );

    const groupFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.GROUP
    );

    const domainFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.DOMAIN
    );

    const multipleGroups =
      reportRule.type === ReportRuleType.GROUP_OWNERS ||
      (contentGroup.type ===
        ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE &&
        reportRule.type === ReportRuleType.SERVICE_OWNERS);

    const groups: AutocompleteOption | Array<AutocompleteOption> =
      multipleGroups ? groupFilter?.values : groupFilter?.values[0];

    const serviceFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.SERVICE
    )?.values;

    const scopeFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.SCOPE
    );

    const selectedFrequencyOption = reportRuleFrequencyOptions.find(
      (frequencyOption) => frequencyOption.value === reportRule.frequencyType
    );

    const riskTimeFrame = contentGroup.configuration?.timeFrame;

    const riskTrendMetricsTypes = contentGroup.configuration?.trendMetricsTypes;

    return {
      name: reportRule.name,
      description: reportRule.description || '',
      recipients: recipients,
      contentType: {
        value: contentGroup?.type,
        label: contentGroup?.label,
      },
      emails:
        emailAction?.data?.recipients?.map((recipient) => ({
          value: recipient,
          label: recipient,
        })) || [],
      frequencyType: selectedFrequencyOption as AutocompleteOption,
      groups: groups,
      scopes: scopeFilter?.values[0] as AutocompleteOption,
      services: serviceFilter as AutocompleteOption[],
      widgets: contentGroup.elements?.map((element) => ({
        value: element.id,
        label: element.label,
      })),
      timeFrame: riskTimeFrame,
      trendMetricsTypes: riskTrendMetricsTypes,
      domains: domainFilter?.values || [],
    };
  }

  transformComparativeReportRuleFormDataToPostData(
    formData: ComparativeReportRuleFormData
  ): ReportRuleModel {
    return {
      name: formData.name,
      type: ReportRuleCreationType.COMPARATIVE,
      description: formData.description,
      scheduleSettings: {
        frequencyType: formData.frequency.value as ReportRuleFrequencyType,
        startTime: new Date(formData.startingOn?.value),
        frequencyUnit: 1,
      },
      recipients:
        formData.mode.value === ReportRuleMode.ROLE_BASED_REPORT
          ? [
              {
                type: 'role',
                value: formData.recipients[0]?.value,
              },
            ]
          : formData.recipients.map((recipient) => ({
              type: 'email',
              value: recipient.value,
            })),
      configuration: {
        comparisonObject: formData.type.value as ComparisonReportObjects,
        comparisonFactors: formData.compareBy.map(
          (comparyByOption) => comparyByOption.value
        ) as Array<ComparisonReportFactor>,
      } as ComparisonReportConfiguration,
      reportScope: {
        domains: formData.domains?.map((domain) => domain.value),
        scopeIds: formData?.scopes ? [formData?.scopes?.value] : [],
        groupIds: formData?.groups
          ? formData?.groups?.map((groupOption) => groupOption.value)
          : [],
      },
      status: ReportRuleStatus.ENABLED,
    };
  }

  transformSecurityReportRuleFormDataToPostData(
    formData: SecurityReportRuleFormData
  ): ReportRuleModel {
    return {
      name: formData.name,
      type: ReportRuleCreationType.SECURITY,
      description: formData.description,
      scheduleSettings: {
        frequencyType: formData.frequency.value as ReportRuleFrequencyType,
        startTime: new Date(formData.startingOn?.value),
        frequencyUnit: 1,
      },
      recipients:
        formData.mode.value === ReportRuleMode.ROLE_BASED_REPORT
          ? [
              {
                type: 'role',
                value: formData.recipients[0]?.value,
              },
            ]
          : formData.recipients.map((recipient) => ({
              type: 'email',
              value: recipient.value,
            })),
      configuration: {
        selectedWidgets: [
          ...formData.widgets.map((widget) => ({
            name: widget.value,
          })),
          {
            name: ReportRuleSecurityWidget.TRENDS_METRICS,
            configuration: {
              metrics: formData.metrics.map((metric) => metric.value),
              timeFrame: formData.timeFrame.value,
            },
          },
          {
            name: ReportRuleSecurityWidget.TRENDS_GRAPH,
            configuration: {
              trendingChartMetrics: formData.trendingChartMetrics.map(
                (trendingChartMetric) => trendingChartMetric.value
              ),
              timeFrame: formData.timeFrame.value,
            },
          },
        ],
      } as SecurityReportConfiguration,
      reportScope: {
        domains: formData.domains?.map((domain) => domain.value),
        scopeIds: formData?.scopes ? [formData?.scopes?.value] : [],
        groupIds: formData?.groups ? [formData?.groups?.value] : [],
        serviceIds: formData.services?.map((service) => service.value),
      },
      status: ReportRuleStatus.ENABLED,
    };
  }

  transformReportRuleToComparativeReportRuleFormData(
    reportRule: ReportRuleModel,
    emailOptions?: Array<any>,
    organizationOptions?: {
      scopeOptions?: Array<AutocompleteOption>;
      groupOptions?: Array<AutocompleteOption>;
    }
  ): ComparativeReportRuleFormData {
    const { scheduleSettings, recipients, configuration, reportScope } =
      reportRule;

    return {
      name: reportRule.name,
      description: reportRule.description,
      type: reportRuleConfigurationTypeOptions.find(
        (option) =>
          option.value ===
          (configuration as ComparisonReportConfiguration).comparisonObject
      )!,
      compareBy: reportRuleCompareByOptions.filter((option) =>
        (
          configuration as ComparisonReportConfiguration
        ).comparisonFactors?.includes(option.value as ComparisonReportFactor)
      )!,
      domains:
        _.compact(
          reportScope.domains?.map((domain) => ({
            value: domain,
            label: domain,
          }))
        ) || [],
      scopes: reportScope.scopeIds?.length
        ? organizationOptions?.scopeOptions?.find(
            (scopeOption) =>
              reportScope.scopeIds &&
              scopeOption.value === reportScope.scopeIds[0]
          )
        : undefined,
      groups: reportScope.groupIds?.length
        ? _.compact(
            reportScope.groupIds.map((groupId) =>
              organizationOptions?.groupOptions?.find(
                (groupOption) =>
                  reportScope.groupIds && groupOption.value === groupId
              )
            )
          )
        : [],
      mode: reportRuleModeOptions.find(
        (option) =>
          option.value ===
          (recipients[0]?.type === 'role'
            ? ReportRuleMode.ROLE_BASED_REPORT
            : ReportRuleMode.STATIC_REPORT)
      )!,
      recipients: _.compact(
        recipients.map((recipient) =>
          recipient.type === 'role'
            ? reportRuleRecipientOptions.find(
                (option) => option.value === recipient.value
              )!
            : emailOptions?.find(
                (option) => option.value === recipient.value
              ) || {
                value: recipient.value,
                label: recipient.value,
              }
        )
      ),
      startingOn: {
        value: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toISOString()
          : '',
        label: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toLocaleDateString()
          : '',
      },
      frequency: reportRuleFrequencyOptions.find(
        (option) => option.value === scheduleSettings.frequencyType
      )!,
    };
  }

  transformReportRuleToSecurityReportRuleFormData(
    reportRule: ReportRuleModel,
    emailOptions?: Array<any>,
    organizationOptions?: {
      scopeOptions?: Array<AutocompleteOption>;
      groupOptions?: Array<AutocompleteOption>;
      serviceOptions?: Array<AutocompleteOption>;
    }
  ): SecurityReportRuleFormData {
    const { scheduleSettings, recipients, configuration, reportScope } =
      reportRule;

    const securityConfiguration = configuration as SecurityReportConfiguration;

    const metrics =
      securityConfiguration.selectedWidgets
        .find(
          (selectedWidget) =>
            selectedWidget.name === ReportRuleSecurityWidget.TRENDS_METRICS
        )
        ?.configuration.metrics.map((metric: string) =>
          operationalDashboardTrendMetricsTypeOptions.find(
            (filterOption) => filterOption.value === metric
          )
        ) || [];

    const trendingChartMetrics =
      securityConfiguration.selectedWidgets
        .find(
          (selectedWidget) =>
            selectedWidget.name === ReportRuleSecurityWidget.TRENDS_GRAPH
        )
        ?.configuration.trendingChartMetrics.map((metric: string) =>
          operationalDashboardTrendMetricsTypeOptions.find(
            (filterOption) => filterOption.value === metric
          )
        ) || [];

    const timeFrame = operationalDashboardFilterOptions.find(
      (filterOption) =>
        filterOption.value ===
        securityConfiguration.selectedWidgets.find(
          (selectedWidget) =>
            selectedWidget.name === ReportRuleSecurityWidget.TRENDS_METRICS
        )?.configuration.timeFrame
    );

    return {
      name: reportRule.name,
      description: reportRule.description,
      widgets: _.compact(
        (configuration as SecurityReportConfiguration).selectedWidgets.map(
          (widget) =>
            reportRuleSecurityWidgetOptions.find(
              (option) => option.value === widget.name
            )!
        )
      ),
      metrics,
      trendingChartMetrics,
      timeFrame: timeFrame || {
        value: (configuration as SecurityReportConfiguration).selectedWidgets[0]
          ?.configuration.timeFrame,
        label: '',
      },
      domains:
        _.compact(
          reportScope.domains?.map((domain) => ({
            value: domain,
            label: domain,
          }))
        ) || [],
      scopes: reportScope.scopeIds?.length
        ? organizationOptions?.scopeOptions?.find(
            (scopeOption) =>
              reportScope.scopeIds &&
              scopeOption.value === reportScope.scopeIds[0]
          )
        : undefined,
      groups: reportScope.groupIds?.length
        ? organizationOptions?.groupOptions?.find(
            (groupOption) =>
              reportScope.groupIds &&
              groupOption.value === reportScope.groupIds[0]
          )
        : undefined,
      services: reportScope.serviceIds?.includes(allOption.value)
        ? organizationOptions?.serviceOptions?.filter(
            (serviceOption) => serviceOption.value !== allOption.value
          ) || []
        : _.compact(
            reportScope.serviceIds?.map((serviceId) =>
              organizationOptions?.serviceOptions?.find(
                (serviceOption) => serviceOption.value === serviceId
              )
            )
          ) || [],
      mode: reportRuleModeOptions.find(
        (option) =>
          option.value ===
          (recipients[0]?.type === 'role'
            ? ReportRuleMode.ROLE_BASED_REPORT
            : ReportRuleMode.STATIC_REPORT)
      )!,
      recipients: _.compact(
        recipients.map((recipient) =>
          recipient.type === 'role'
            ? reportRuleRecipientOptions.find(
                (option) => option.value === recipient.value
              )!
            : emailOptions?.find(
                (option) => option.value === recipient.value
              ) || {
                value: recipient.value,
                label: recipient.value,
              }
        )
      ),
      startingOn: {
        value: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toISOString()
          : '',
        label: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toLocaleDateString()
          : '',
      },
      frequency: reportRuleFrequencyOptions.find(
        (option) => option.value === scheduleSettings.frequencyType
      )!,
    };
  }

  generateReportRulePreviewUrl(reportRule: ReportRuleModel) {
    return `/internal/dashboards/reports?name=${reportRule.name}&description=${
      reportRule.description
    }&type=${reportRule.type}&scheduleSettings=${JSON.stringify(
      reportRule.scheduleSettings
    )}&configuration=${JSON.stringify(
      reportRule.configuration
    )}&reportScope=${JSON.stringify(reportRule.reportScope)}`;
  }
}
