import { useFetchUsersMutation } from 'Auth/store/api';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { useEffect, useMemo, useState } from 'react';
import { reportRuleFilterAllOption } from 'shared/fixtures/data/report-rule.data';
import { OrganizationNodeDataHandler } from 'shared/handlers/organization-node-data.handler';
import { UserDataHandler } from 'shared/handlers/user-data.handler';
import {
  ReportRule,
  ReportRuleModel,
  ReportRulePropertyType,
  ReportRuleType,
} from '../models/data/report-rule.model';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';

export type NotificationSettingsOptionList = {
  findingTypeOptionList: Array<AutocompleteOption>;
  emailOptionList: Array<AutocompleteOption>;
  scopesOptionList?: Array<AutocompleteOption>;
  groupsOptionList?: Array<AutocompleteOption>;
  servicesOptionList?: Array<AutocompleteOption>;
  findingTypeOptionListLoading: boolean;
  scopesOptionsLoading: boolean;
  groupsOptionsLoading: boolean;
  servicesOptionsLoading: boolean;
  getGroupsOptionList: (scopeId?: string) => void;
  getServiceOptionList: (startNodeId?: string) => void;
};

const userDataHandler = new UserDataHandler();

const organizationNodeDataHandler = new OrganizationNodeDataHandler();

export function useFetchReportRulesOptionItems(
  reportRule?: ReportRuleModel
): NotificationSettingsOptionList {
  const [
    fetchFindingTypes,
    { data: findingTypes, isLoading: findingTypesLoading },
  ] = useFetchFilterInformationForFieldMutation();

  const [fetchUsers, { data: userList }] = useFetchUsersMutation();

  const [fetchScopeList, { data: scopeList, isLoading: scopeListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [fetchGroupList, { data: groupList, isLoading: groupListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [fetchServiceList, { data: services, isLoading: areServicesLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    fetchFindingTypes({
      field: 'findingType',
    });
    if (!reportRule) {
      getDefaultNodeOptions();
    } else {
      getNodeOptionsForReportRule();
    }
  }, [reportRule]);

  const getDefaultNodeOptions = () => {
    fetchUsers();
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
    });
  };

  const getNodeOptionsForReportRule = () => {
    fetchUsers();
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });

    const scopeFilter = reportRule?.reportScope.scopeIds as Array<string>;
    const selectedScope = scopeFilter[0];
    const fetchGroupsParams =
      selectedScope && selectedScope !== 'All'
        ? {
            nodesType: OrganizationNodeType.LOGICAL,
            startNodeId: selectedScope,
          }
        : {
            nodesType: OrganizationNodeType.LOGICAL,
          };
    fetchGroupList(fetchGroupsParams);

    const groupFilter = reportRule?.reportScope.groupIds as Array<string>;
    const selectedGroup = groupFilter[0];
    const fetchServicesParams: any = {
      nodesType: OrganizationNodeType.DATA,
    };
    if (selectedGroup === 'All') {
      if (selectedScope !== 'All') {
        fetchServicesParams.startNodeId = selectedScope;
      }
    } else {
      fetchServicesParams.startNodeId = selectedGroup;
    }
    fetchServiceList(fetchServicesParams);
  };

  const userEmailList = useMemo(() => {
    if (userList) {
      return userDataHandler.transformUserListToAutocompleteEmailList(userList);
    }

    return [];
  }, [userList]);

  const scopesOptionList = useMemo(() => {
    if (scopeList) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeList
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return [
      {
        value: 'All',
        label: 'All',
      },
    ];
  }, [scopeList]);

  const groupsOptionList = useMemo(() => {
    if (groupList) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          groupList
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return [
      {
        value: 'All',
        label: 'All',
      },
    ];
  }, [groupList]);

  const servicesOptionList = useMemo(() => {
    if (services) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          services
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return undefined;
  }, [services]);

  const findingTypeOptionList = useMemo(() => {
    if (findingTypes)
      return findingTypes.map((findingType: AutocompleteOption) => ({
        value: findingType.value,
        label: findingType.value,
      }));

    return [];
  }, [findingTypes]);

  const getGroupsOptionList = (scopeId?: string) => {
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
      startNodeId: scopeId,
    });
  };

  const getServiceOptionList = (startNodeId?: string) => {
    fetchServiceList({
      nodesType: OrganizationNodeType.DATA,
      startNodeId: startNodeId,
    });
  };

  return {
    findingTypeOptionList: findingTypeOptionList,
    emailOptionList: userEmailList,
    scopesOptionList: scopesOptionList,
    groupsOptionList: groupsOptionList,
    servicesOptionList: servicesOptionList,
    getGroupsOptionList: getGroupsOptionList,
    getServiceOptionList: getServiceOptionList,
    scopesOptionsLoading: scopeListLoading,
    groupsOptionsLoading: groupListLoading,
    servicesOptionsLoading: areServicesLoading,
    findingTypeOptionListLoading: findingTypesLoading,
  };
}
