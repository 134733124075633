import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';
import { OrderState } from 'Common/utils/sort';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import {
  FilterItem,
  FilterOption,
} from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import {
  FindingState,
  codeOriginGroupGridColumns,
  codeOriginGroupNestedColumns,
  noneRiskTableColumns,
  resourceGroupGridColumns,
  resourceGroupNestedColumns,
  riskGroupFilterItems,
  riskGroupGridColumns,
  riskGroupNestedColumns,
  riskViewFilterCategories,
  rootCauseGridColumns,
  excludedRiskFilters,
  vulnerabilityRiskFilters,
  packageGroupGridColumns,
  packageGroupNestedColumns,
} from 'shared/fixtures/data/risk-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import {
  CategoryState,
  KeyValueState,
} from 'shared/models/data/data-filter.model';

export enum RiskStoreLocalVariable {
  GridColumns = 'risk_grid_columns',
  GridDuplicationToggle = 'risk_duplication_toggle',
}
export interface RiskFilterItem {
  value: string;
  label?: string;
  iconUrl?: string;
}

export interface RiskFilters {
  [key: string]: Array<RiskFilterItem> | RiskFilterItem | string;
}

export interface RiskStoreSearchParams {
  filter: RiskFilters;
  order?: OrderState;
  skip: number;
  take: number;
  removeDuplications?: boolean;
}

export interface RiskItem {
  id: string;
  name?: string;
  status?: string;
}

export enum GridType {
  Risk = 'risk',
  ResourceName = 'resourceName',
  CodeOrigin = 'codeOrigin',
  RootCause = 'rootCause',
  None = 'none',
  Package = 'packageName',
}

export enum GridSelectionType {
  ALL = 'all',
  PARTIAL = 'partial',
  MANUAL = 'manual',
  UNSELECTED = 'unselected',
}

export interface GridSelectionChild {
  id: string;
  rowCount: number;
}

export interface GridSelectionProps {
  availableChildren: Array<GridSelectionChild | string | number>;
  selectedChildren: Array<string | number>;
  rows?: Array<GridRowSelectionProps>;
  totalSelectedChildren: number;
  totalAvailableChildren: number;
}

export interface GridRowSelectionProps {
  value?: string | number;
  totalAvailableChildren: number;
  totalSelectedChildren: number;
  availableChildren: Array<string | number>;
  unselectedChildren: Array<string | number>;
  selection: GridSelectionType;
  rows?: Array<GridSelectionProps>;
}

interface RiskStore {
  searchParams: RiskStoreSearchParams;
  activeFindingType: any | null;
  riskGroupTableSelectionProps: GridSelectionProps;
  resourceGroupTableSelectionProps: GridSelectionProps;
  codeOriginGroupTableSelectionProps: GridSelectionProps;
  rootCauseGroupTableSelectionProps: GridSelectionProps;
  packageGroupTableSelectionProps: GridSelectionProps;
  riskTableSelectionProps: GridSelectionProps;
  activeGridTab: GridType;
  gridFilterState: MenuItems;
  filterState: Record<string, CategoryState>;
  gridFilterSelections: SelectionItems;
  gridColumns: Partial<
    Record<GridType | `${GridType}_nested`, Array<ColDef | ColGroupDef>>
  >;
  gridDuplicationState: boolean;
}

export const riskGroupTableSelectionDefaultProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

export const rootCauseGroupTableDefaultSelectionProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

export const packageGroupTableDefaultSelectionProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

export const resourceGroupTableSelectionDefaultProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

export const codeOriginGroupTableSelectionDefaultProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

export const riskTableSelectionDefaultProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

const getInitialDuplicationState = (): boolean => {
  if (localStorage.getItem(RiskStoreLocalVariable.GridDuplicationToggle)) {
    try {
      const duplicationToggle = JSON.parse(
        localStorage.getItem(
          RiskStoreLocalVariable.GridDuplicationToggle
        ) as string
      );

      return duplicationToggle;
    } catch (err) {
      return false;
    }
  }

  return false;
};

const advanceFilterDataHandler = new AdvanceFilterHandler();

export const getInitialFilterState = () => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has('filter')) {
    try {
      const queryFilters = JSON.parse(searchParams.get('filter') || '');

      if (Object.keys(queryFilters).length === 0) {
        return {
          state: {
            selectedOptions: [
              {
                value: FindingState.ACTIVE,
                label: FindingState.ACTIVE,
              },
            ],
          },
        };
      }

      const filterState =
        advanceFilterDataHandler.translateQueryFiltersToFilterState(
          queryFilters,
          riskViewFilterCategories
        );

      return filterState;
    } catch (err) {
      return {
        state: {
          selectedOptions: [
            {
              value: FindingState.ACTIVE,
              label: FindingState.ACTIVE,
            },
          ],
        },
      };
    }
  }
  {
    return {
      state: {
        selectedOptions: [
          {
            value: FindingState.ACTIVE,
            label: FindingState.ACTIVE,
          },
        ],
      },
    };
  }
};

const initialState: RiskStore = {
  searchParams: {
    skip: 0,
    take: 25,
    filter: {},
  },
  activeFindingType: null,
  riskGroupTableSelectionProps: riskGroupTableSelectionDefaultProps,
  resourceGroupTableSelectionProps: resourceGroupTableSelectionDefaultProps,
  codeOriginGroupTableSelectionProps: codeOriginGroupTableSelectionDefaultProps,
  rootCauseGroupTableSelectionProps: rootCauseGroupTableDefaultSelectionProps,
  packageGroupTableSelectionProps: packageGroupTableDefaultSelectionProps,
  riskTableSelectionProps: riskTableSelectionDefaultProps,
  activeGridTab: GridType.Risk,
  gridFilterState: riskGroupFilterItems,
  gridFilterSelections: [],
  gridColumns: {
    [GridType.Risk]: riskGroupGridColumns,
    [`${GridType.Risk}_nested`]: riskGroupNestedColumns,
    [GridType.ResourceName]: resourceGroupGridColumns,
    [`${GridType.ResourceName}_nested`]: resourceGroupNestedColumns,
    [GridType.Package]: packageGroupGridColumns,
    [`${GridType.Package}_nested`]: packageGroupNestedColumns,
    [GridType.CodeOrigin]: codeOriginGroupGridColumns,
    [`${GridType.CodeOrigin}_nested`]: codeOriginGroupNestedColumns,
    [GridType.RootCause]: rootCauseGridColumns,
    [GridType.None]: noneRiskTableColumns,
  },
  gridDuplicationState: getInitialDuplicationState(),
  filterState: getInitialFilterState(),
};

export const riskSlice = createSlice({
  name: 'risks',
  initialState,
  reducers: {
    setSearchParams: (
      state,
      action: PayloadAction<Partial<RiskStoreSearchParams>>
    ) => {
      if (action.payload && Object.keys(action.payload).length) {
        state.searchParams = {
          ...state.searchParams,
          ...action.payload,
        };
      }
    },
    setActiveFindingType: (state, action: PayloadAction<any>) => {
      state.activeFindingType = action.payload;
    },

    setRiskGroupTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.riskGroupTableSelectionProps = action.payload;
    },

    setRiskTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.riskTableSelectionProps = action.payload;
    },

    setRootCauseGroupTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.rootCauseGroupTableSelectionProps = action.payload;
    },

    setPackageGroupTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.packageGroupTableSelectionProps = action.payload;
    },
    setCodeOriginGroupTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.codeOriginGroupTableSelectionProps = action.payload;
    },
    setResourceGroupTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.resourceGroupTableSelectionProps = action.payload;
    },
    setActiveGridTab: (state, action: PayloadAction<GridType>) => {
      state.activeGridTab = action.payload;
    },
    setGridDuplicationState: (state, action: PayloadAction<boolean>) => {
      state.gridDuplicationState = action.payload;
      localStorage.setItem(
        RiskStoreLocalVariable.GridDuplicationToggle,
        JSON.stringify(state.gridDuplicationState)
      );
    },
    setGridFilterState: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      let gridType: GridType = state.activeGridTab;

      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (object.nested) {
          const nestedFilterItems: Array<FilterItem> = object.filterItems || [];

          for (const filterItem of nestedFilterItems) {
            if (filterItem.items.some((item: FilterOption) => item.checked)) {
              updatedSelections.push({
                id: filterItem.id,
                type: TypeFilter.MULTI_SELECT,
                selectionValues: filterItem.items?.filter(
                  (item: FilterOption) => item.checked
                ),
              });
            }
          }
        }

        if (
          object.dateRange ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      if (
        Array.isArray(action.payload) === false &&
        (action.payload as { list: MenuItems; isSelectingItem: boolean })
          .isSelectingItem
      ) {
        state.gridFilterSelections = updatedSelections;
      }
      state.gridFilterState = modifiedArray;
    },
    setGridColumns: (
      state,
      action: PayloadAction<{
        gridType: GridType | `${GridType}_nested`;
        columns: Array<ColDef | ColGroupDef>;
      }>
    ) => {
      state.gridColumns[action.payload.gridType] = action.payload.columns;

      localStorage.setItem(
        RiskStoreLocalVariable.GridColumns,
        JSON.stringify(state.gridColumns)
      );
    },
    clearGridFilterState: (state, action: PayloadAction<void>) => {
      let emptyFilterState: MenuItems = [...initialState.gridFilterState];

      for (const gridFilterStateKey in state.gridFilterState) {
        const filterState = state.gridFilterState;

        const updateFilterStateItems = filterState.map((obj: any) => {
          if (!obj?.dateRange) {
            return {
              ...obj,
              dateRange: null,
              items: obj.items.map((item: any) => {
                return { ...item, checked: false };
              }),
            };
          }
          return {
            ...obj,
            dateRange: null,
          };
        });

        emptyFilterState = updateFilterStateItems;
      }

      state.gridFilterState = emptyFilterState;
    },

    setFilterState: (
      storeState,
      action: PayloadAction<{ categoryId: string; state: CategoryState }>
    ) => {
      const { categoryId, state } = action.payload;

      let updatedFilterState = { ...storeState.filterState };

      if (categoryId === 'scopeId' && updatedFilterState['groupId']) {
        updatedFilterState['groupId'] = {
          selectedOptions: [],
        };
      }

      updatedFilterState = {
        ...updatedFilterState,
        [categoryId]: state,
      };

      storeState.filterState = updatedFilterState;
    },
    removeFilterItem: (
      state,
      action: PayloadAction<{ categoryId: string }>
    ) => {
      const { categoryId } = action.payload;

      let updatedFilterState = { ...state.filterState };

      delete updatedFilterState[categoryId];

      state.filterState = updatedFilterState;
    },

    clearFilterState: (state) => {
      state.filterState = {};
    },

    replaceFilterState: (
      state,
      action: PayloadAction<{
        updatedState: Record<string, CategoryState>;
      }>
    ) => {
      const { updatedState } = action.payload;

      state.filterState = {
        ...updatedState,
      };
    },
  },
});

export const {
  setSearchParams,
  setActiveFindingType,
  setRiskGroupTableSelection,
  setResourceGroupTableSelection,
  setRootCauseGroupTableSelection,
  setRiskTableSelection,
  setGridFilterState,
  setActiveGridTab,
  clearGridFilterState,
  setCodeOriginGroupTableSelection,
  setGridColumns,
  setGridDuplicationState,
  setFilterState,
  removeFilterItem,
  clearFilterState,
  replaceFilterState,
  setPackageGroupTableSelection,
} = riskSlice.actions;

export const getRiskSearchParams = (state: RootState) =>
  state.risks.searchParams;

export const getActiveFindingType = (state: RootState) =>
  state.risks.activeFindingType;

export const getRiskGroupTableSelectionProps = (
  state: RootState
): GridSelectionProps => state.risks.riskGroupTableSelectionProps;

export const getResourceGroupTableSelectionProps = (
  state: RootState
): GridSelectionProps => state.risks.resourceGroupTableSelectionProps;

export const getCodeOriginGroupTableSelectionProps = (
  state: RootState
): GridSelectionProps => state.risks.codeOriginGroupTableSelectionProps;

export const getRootCauseGroupTableSelectionProps = (
  state: RootState
): GridSelectionProps => state.risks.rootCauseGroupTableSelectionProps;
export const getPackageGroupTableSelectionProps = (
  state: RootState
): GridSelectionProps => state.risks.packageGroupTableSelectionProps;

export const getRiskTableSelectionProps = (
  state: RootState
): GridSelectionProps => state.risks.riskTableSelectionProps;

export const getActiveGridTab = (state: RootState): GridType =>
  state.risks.activeGridTab;

export const getGridFilterState = (state: RootState): MenuItems =>
  state.risks.gridFilterState;

export const getGridFilterSelections = (state: RootState): SelectionItems =>
  state.risks.gridFilterSelections;

export const getGridColumnsByGridType =
  (gridType: GridType | `${GridType}_nested`) =>
  (state: RootState): Array<ColDef | ColGroupDef> => {
    if (localStorage.getItem(RiskStoreLocalVariable.GridColumns)) {
      try {
        const storedGridColumns = JSON.parse(
          localStorage.getItem(RiskStoreLocalVariable.GridColumns) as string
        );

        const gridColumns = storedGridColumns[gridType] || [];
        const stateGridColumns = state.risks.gridColumns[gridType] || [];

        const storedColumns = gridColumns.map(
          (gridColumn: ColDef) => gridColumn.field
        );
        const columns = stateGridColumns.map(
          (gridColumn: ColDef) => gridColumn.field
        );

        if (!storedColumns.every((val: string) => columns.includes(val))) {
          localStorage.removeItem(RiskStoreLocalVariable.GridColumns);
          return stateGridColumns;
        }

        if (stateGridColumns.length > gridColumns.length) {
          return stateGridColumns;
        }

        return gridColumns.map((gridColumn: ColDef) => {
          const stateGridColumn: ColDef = stateGridColumns.find(
            (stateGridColumn: ColDef) =>
              stateGridColumn.field === gridColumn.field
          ) as ColDef;

          return {
            ...gridColumn,
            cellRenderer: stateGridColumn && stateGridColumn?.cellRenderer,
          };
        });
      } catch (err) {
        return state.risks.gridColumns[gridType] || [];
      }
    } else {
      return state.risks.gridColumns[gridType] || [];
    }
  };

export const getGridDuplicationState = (state: RootState) => {
  return state.risks.gridDuplicationState;
};

export const getFilterState = (state: RootState) => state.risks.filterState;
export const getAdditionalSearchParams = (state: RootState) => {
  const updatedFilterState = Object.keys(state.risks.filterState).reduce(
    (accumulator, filterStateKey: string) => {
      if (!excludedRiskFilters.includes(filterStateKey)) {
        accumulator[filterStateKey] = state.risks.filterState[filterStateKey];
      }
      return accumulator;
    },
    {} as any
  );

  return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
    updatedFilterState,
    riskViewFilterCategories
  );
};
export const getVulnerabilitySearchParams = (state: RootState) => {
  const updatedFilterState = Object.keys(state.risks.filterState).reduce(
    (accumulator, filterStateKey: string) => {
      if (vulnerabilityRiskFilters.includes(filterStateKey)) {
        accumulator[filterStateKey] = state.risks.filterState[filterStateKey];
      }
      return accumulator;
    },
    {} as any
  );

  return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
    updatedFilterState,
    riskViewFilterCategories
  );
};
export const getTagsSearchParams = (state: RootState) => {
  if (state.risks.filterState.tags) {
    const tagsState = state.risks.filterState.tags as KeyValueState;

    const tagsFilterPayload =
      advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
        {
          tags: {
            ...tagsState,
            selections: tagsState.selections?.filter(
              (selection) => selection.key.value.length > 0
            ),
          },
        },
        riskViewFilterCategories
      );

    return tagsFilterPayload.tags;
  }

  return {};
};

const riskReducer = riskSlice.reducer;
export default riskReducer;
