import { CircularProgress } from '@mui/material';
import { OrderState } from 'Common/utils/sort';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useMemo } from 'react';
import {
  getAdditionalSearchParams,
  getGridColumnsByGridType,
  getGridDuplicationState,
  getPackageGroupTableSelectionProps,
  getRiskGroupTableSelectionProps,
  getRiskSearchParams,
  getTagsSearchParams,
  getVulnerabilitySearchParams,
  GridSelectionProps,
  GridType,
  setGridColumns,
  setPackageGroupTableSelection,
  setSearchParams,
} from 'Risk/store';
import { CommonAdvancedDataGrid } from 'shared/components/CommonAdvancedDataGrid/CommonAdvancedDataGrid';
import {
  getAllColumnDefs,
  getRiskSearchParamsRequestDependencies,
  nestedPackageGroupDefaultOrder,
} from 'shared/fixtures/data/risk-grid.data';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { GridSelectionHandler } from 'shared/handlers/grid-selection.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { NoDataBackdrop } from 'shared/components/NoDataBackdrop/NoDataBackdrop';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { RiskFilterItem } from 'Risk/store';
import { useSearchPackageNameGroupsMutation } from 'Risk/store/api';

interface PackageGroupGridProps extends BaseComponentProps {
  clearFilters: () => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  gridRef?: any;
}

const riskSearchParamsHandler = new FilterSearchParamsHandler();

const packageGroupGridSelectionHandler = new GridSelectionHandler();

export const PackageGroupGrid: FunctionComponent<PackageGroupGridProps> = ({
  onRowClicked,
  gridRef,
}) => {
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const isDuplicatedStateEnabled = useCommonSelector(getGridDuplicationState);
  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (urlSearchParams.order) {
        const parsedOrderParams = JSON.parse(
          urlSearchParams.order as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [urlSearchParams.order]);

  const searchParams = useCommonSelector(getRiskSearchParams);

  const packageGroupGridColumns = useCommonSelector(
    getGridColumnsByGridType(GridType.Package)
  );

  const packageGroupNestedColumns = useCommonSelector(
    getGridColumnsByGridType(
      `${GridType.Package}_nested` as `${GridType.Package}_nested`
    )
  );

  const packageGroupGridSelectionProps = useCommonSelector(
    getPackageGroupTableSelectionProps
  );

  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const tagsSearchParams = useCommonSelector(getTagsSearchParams);

  const dispatch = useCommonDispatch();

  const [
    searchPackageNameGroup,
    { data: packageNameGroupPayload, isLoading: isPackageNameGroupLoading },
  ] = useSearchPackageNameGroupsMutation();

  const titleValue = useMemo<string>(() => {
    const titleRiskFilterItem = searchParams?.filter?.title as RiskFilterItem;
    return titleRiskFilterItem?.value;
  }, [searchParams]);

  useEffect(() => {
    searchPackageNameGroup({
      ...searchParams,
      filter: {
        ...additionalSearchParams,
        ...searchParams.filter,
      },
      order: orderParams,
      removeDuplications: isDuplicatedStateEnabled,
      vulnerabilityFilter: vulnerabilitySearchParams,
      tagFilter: tagsSearchParams,
    });
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    searchParams?.skip,
    searchParams?.take,
    titleValue,
    orderParams,
    isDuplicatedStateEnabled,
  ]);

  useEffect(() => {
    if (packageNameGroupPayload) {
      const updatedPackageGroupGridSelectionProps =
        packageGroupGridSelectionHandler.handleGridSelectionStateOnSearchParamsChange(
          {
            responsePayload: packageNameGroupPayload,
            currentGridSelectionProps: packageGroupGridSelectionProps,
          },
          GridType.Package
        );

      dispatch(
        setPackageGroupTableSelection(updatedPackageGroupGridSelectionProps)
      );
    }
  }, [packageNameGroupPayload]);

  const resetPage = () => {
    if (searchParams.skip !== 0)
      dispatch(
        setSearchParams({
          ...searchParams,
          skip: 0,
        })
      );
  };

  useEffect(() => {
    resetPage();
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    orderParams,
    isDuplicatedStateEnabled,
    searchParams?.filter?.title,
  ]);

  const onPageChange = (pageNumber: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        skip: searchParams.take * (pageNumber - 1),
      })
    );
  };

  const onPageSizeChange = (pageSize: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        take: pageSize,
        skip: 0,
      })
    );
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    const existingParams = riskSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['activeTabId', 'filter', 'searchKeyword']
    );

    if (columnOrderPayload.length) {
      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: columnOrderPayload[0].field,
          type: columnOrderPayload[0].type,
        }),
      });
    } else {
      delete existingParams.order;

      setUrlSearchParams({
        ...existingParams,
      });
    }
  };

  const onHandleChildSelectionChange = (
    selection: string,
    parentId: string,
    selectionProps: GridSelectionProps
  ) => {
    const updatedPackageGroupGridSelectionProps =
      packageGroupGridSelectionHandler.handleNestedGridRowSelection({
        currentGridSelectionProps: selectionProps,
        selectionId: parentId,
        nestedSelectionId: selection,
      });

    dispatch(
      setPackageGroupTableSelection(updatedPackageGroupGridSelectionProps)
    );
  };

  const populateSelectionValues = (
    parentId: string,
    selectionValues: Array<string>,
    payload: any,
    selectionProps: GridSelectionProps
  ) => {
    const updatedPackageGroupGridSelectionProps =
      packageGroupGridSelectionHandler.handleNestedGridSelectionStateOnSearchParamsChange(
        {
          currentGridSelectionProps: selectionProps,
          selectionId: parentId,
          responsePayload: payload,
        }
      );

    dispatch(
      setPackageGroupTableSelection(updatedPackageGroupGridSelectionProps)
    );
  };

  return (
    <CommonAdvancedDataGrid
      columnDefs={packageGroupGridColumns}
      isLoading={isPackageNameGroupLoading}
      rowData={packageNameGroupPayload?.data}
      rowSelection="multiple"
      defaultColDef={{
        resizable: true,
        sortable: true,
      }}
      otherComponents={{
        NoDataBackdropComponent: (
          <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
        ),
      }}
      getRowId={(params) => params.data?.aggregatedValue}
      rowIdProperty="aggregatedValue"
      suppressRowClickSelection
      gridRef={gridRef}
      loadingOverlayComponent={() => <CircularProgress />}
      paginationProps={{
        pageSize: searchParams.take,
        currentPage: searchParams.skip
          ? searchParams.skip / searchParams.take + 1
          : 1,
        onPageChange,
        onPageSizeChange,
        totalItems: packageNameGroupPayload?.totalItems || 0,
      }}
      selectionModel={
        packageGroupGridSelectionProps.selectedChildren as Array<string>
      }
      sortModel={orderParams as OrderState}
      onSelect={(selectionIds: Array<string>) => {
        const updatedPackageGroupSelectionProps =
          packageGroupGridSelectionHandler.handleGridSelectionChanges({
            currentGridSelectionProps: packageGroupGridSelectionProps,
            responsePayload: packageNameGroupPayload,
            selectionIds: selectionIds as Array<string>,
          });

        dispatch(
          setPackageGroupTableSelection(updatedPackageGroupSelectionProps)
        );
      }}
      onColumnMoved={(event: ColumnMovedEvent) => {
        if (event.finished) {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType: GridType.Package,
              columns: getAllColumnDefs(
                packageGroupGridColumns,
                updatedColumnDefs || []
              ),
            })
          );
        }
      }}
      onSort={onHandleColumnOrderChange}
      nestedTableProps={{
        columnDefs: packageGroupNestedColumns,
        visibilityControlProps: {
          enableVisibilityControls: false,
          columns: [],
        },
        gridDuplicationState: getGridDuplicationState,
        dataMutation: useFetchFindingsWithResourceMutation,
        getRowId: (params) => params.data.findingId,
        populateSelectionValues,
        gridSelectionPropsSelector: getPackageGroupTableSelectionProps,
        onHandleChildSelectionChange,
        filterParams: {
          ...searchParams.filter,
          ...additionalSearchParams,
        },
        additionalMutationParameters: {
          vulnerabilityFilter: vulnerabilitySearchParams,
        },
        sortModel: nestedPackageGroupDefaultOrder as OrderState,
        loadingOverlayComponent: () => <CircularProgress />,
        onRowClicked: (event: RowClickedEvent) => {
          onRowClicked && onRowClicked(event);
        },
        onColumnMoved: (event: ColumnMovedEvent) => {
          if (event.finished) {
            const updatedColumnDefs = event.api.getColumnDefs();

            dispatch(
              setGridColumns({
                gridType:
                  `${GridType.Package}_nested` as `${GridType.Package}_nested`,
                columns: getAllColumnDefs(
                  packageGroupNestedColumns,
                  updatedColumnDefs || []
                ),
              })
            );
          }
        },
        onColumnVisible: (event: ColumnVisibleEvent) => {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType:
                `${GridType.Package}_nested` as `${GridType.Package}_nested`,
              columns: getAllColumnDefs(
                packageGroupNestedColumns,
                updatedColumnDefs || []
              ),
            })
          );
        },
      }}
    />
  );
};
